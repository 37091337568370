import React, {useEffect, useState} from 'react';
import {
    Datagrid,
    fetchEnd,
    fetchStart,
    Filter,
    List,
    ReferenceField,
    SelectField,
    SelectInput,
    TextField,
    useDataProvider,
    useTranslate
} from 'react-admin';
import {parse} from 'query-string';
import {TooltipTextField} from "../components";
import {goServiceTrips} from "../components/CommonUtils";
import {LinkButton} from "../components/buttons";
import {CardActions} from '@material-ui/core';
import {useDispatch} from 'react-redux';

const directionChoices = [
    {id: 1, name: 'tripType.outbound'},
    {id: 2, name: 'tripType.inbound'}
];

const tripTypeChoices = [
    {id: 'ONEWAY_PAX', name: 'tripType.oneWayPax'},
    {id: 'RETURN_PAX', name: 'tripType.returnPax'},
];

export const UiProductOptions = (props) => {
    const [productId, setProductId] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const translate = useTranslate();

    useEffect(() => {
        function getProductData(id) {
            dispatch(fetchStart());
            dataProvider.getOne('products', {id: id})
                .then(response => {
                    setShortDescription(response.data.shortDescription)
                })
                .finally(() => {
                    dispatch(fetchEnd());
                })
        }

        if (props.location &&
            props.location.search) {
            const par1 = parse(props.location.search.substring(1));
            const par2 = JSON.parse(par1['filter']);
            setProductId(par2['productId']);
            getProductData(par2['productId']);
        }
    }, [props.location, dataProvider, dispatch]);

    const getTitle = () => {
        let title = translate('productOptions.title');
        if (shortDescription) {
            title = `${shortDescription} ${translate('productOptions.title')}`;
        }
        return title;
    };

    const Filters = (filterProps) => (
        <Filter {...filterProps} variant="outlined">
            <SelectInput
                source={'direction'}
                choices={directionChoices}
                alwaysOn
                clearAlwaysVisible={false}
                resettable
                emptyText={'allSelectInput.emptyText'}
                options={{
                    SelectProps: {displayEmpty: true},
                    InputLabelProps: {shrink: true}
                }}
            />
            <SelectInput
                source={'tripType'}
                choices={tripTypeChoices}
                alwaysOn
                clearAlwaysVisible={false}
                resettable
                emptyText={'allSelectInput.emptyText'}
                options={{
                    SelectProps: {displayEmpty: true},
                    InputLabelProps: {shrink: true}
                }}
            />
        </Filter>
    );

    const ListActions = ({resource, filters, displayedFilters, filterValues, showFilter}) => {
        return (
            <CardActions>
                {filters && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
                <LinkButton to={'/products'} label={translate('productOptions.productsButton')}/>
            </CardActions>
        );
    };


    if (!productId) {
        return <div/>
    }

    return (
        <List
            title={getTitle()}
            bulkActionButtons={false}
            filter={{productId: productId}}
            filters={<Filters/>}
            actions={<ListActions/>}
            filterDefaultValues={{direction: 1}}
            {...props}
        >
            <Datagrid hover={false}>
                <TextField source={'id'}/>
                <TextField source={'shortDescription'}/>
                <TextField source={'description'}/>
                <SelectField
                    source={'tripTypeId'}
                    choices={tripTypeChoices}
                    sortable={false}
                />
                <ReferenceField
                    source={'serviceId'}
                    reference={'services'}
                    link={false}
                    sortable={false}
                    allowEmpty={true}
                >
                    <TooltipTextField source={'id'} tooltipSource={'shortDescription'} link={goServiceTrips}/>
                </ReferenceField>
                <SelectField
                    choices={directionChoices}
                    source={'direction'}
                />
                <TooltipTextField source={'originStopId'} tooltipSource={'origin'} sortable={false}/>
                <TooltipTextField source={'destinationStopId'} tooltipSource={'destination'} sortable={false}/>
            </Datagrid>
        </List>
    );
};
