import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import inflection from 'inflection';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import DefaultIcon from '@material-ui/icons/ViewList';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {getResources, MenuItemLink, withTranslate} from 'react-admin';

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
};

const translatedResourceName = (resource, translate) =>
    translate(`resources.${resource.name}.name`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? translate(resource.options.label, {
                    smart_count: 2,
                    _: resource.options.label,
                })
                : inflection.humanize(inflection.pluralize(resource.name)),
    });

const CustomMenu = ({
                        classes,
                        className,
                        dense,
                        hasDashboard,
                        onMenuClick,
                        open,
                        pathname,
                        resources,
                        translate,
                        ...rest
                    }) => (
    <div className={classnames(classes.main, className)} {...rest}>
        {hasDashboard && <MenuItemLink
            onClick={onMenuClick}
            to={"/"}
            primaryText={translate('ra.page.dashboard')}
            leftIcon={<DashboardIcon style={{fontSize: 24}}/>}
            exact
            dense={dense}
            style={{fontSize: 16}}
            sidebarIsOpen={open}
        />}
        {resources
            .filter(r => r.hasList)
            .filter(r => (!r.options.hideMenu))
            .map(resource => {
                return (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translatedResourceName(resource, translate)}
                        style={{fontSize: 16}}
                        leftIcon={
                            resource.icon ? <resource.icon style={{fontSize: 24}}/> :
                                <DefaultIcon style={{fontSize: 24}}/>
                        }
                        onClick={onMenuClick}
                        dense={dense}
                        sidebarIsOpen={open}
                    />
                )
            })}
    </div>
);

CustomMenu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
    open: PropTypes.bool,
    pathname: PropTypes.string,
    resources: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired,
};

CustomMenu.defaultProps = {
    onMenuClick: () => null,
};

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    resources: getResources(state),
    pathname: state.router.location.pathname, // used to force redraw on navigation
});

const enhance = compose(
    withTranslate,
    connect(
        mapStateToProps,
        {}, // Avoid connect passing dispatch in props,
        null,
        {
            areStatePropsEqual: (prev, next) =>
                prev.resources.every(
                    (value, index) => value === next.resources[index] // shallow compare resources
                ) &&
                prev.pathname === next.pathname &&
                prev.open === next.open,
        }
    ),
    withStyles(styles)
);

export default enhance(CustomMenu);
