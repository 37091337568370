import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel} from '@material-ui/core';
import {SelectInput, useDataProvider, useTranslate} from 'react-admin'
import {Download, KDialogTitle} from './';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {stringify} from 'query-string';
import {LANGUAGE} from "../authClient";
import {
    defaultFormState,
    getAgencies,
    getAgents,
    getExportVariants,
    getFirstElement,
    noAction,
    renderMenuItems,
    useExportDialogStyle
} from "./CommonUtils";
import {CustomSelectInput, DateInputPicker} from "./input";
import {CustomForm} from './form';

const initialFormValues = {
    agencyId: null,
    agentId: null,
    fromDate: moment().startOf('day').format("YYYY-MM-DD"),
    toDate: null
};

const toDateMutator = () => ({
    changeToDate: ([[value, extCondition]], state, utils) => {
        if (value !== state.formState.values['toDate'] && extCondition) {
            utils.changeValue(state, 'toDate', () => value)
        }
    }
});

const validator = (checked, translate) => (values) => {
    const errors = {};
    ['agencyId', 'fromDate'].forEach(field => {
        if (!values[field]) {
            errors[field] = translate("ra.validation.required");
        }
    });
    ['fromDate', 'toDate'].forEach(field => {
        if (values[field] && !moment(values[field]).isValid()) {
            errors[field] = translate('ra.validation.invalid_date')
        }
    });
    if (checked && !values.toDate) {
        errors.toDate = translate("ra.validation.required");
    }
    return errors;
};

function getTitle(file) {
    let title = `dialogExportReport.defaultTitle`;
    if (file) {
        title = `reports.files.${file}`;
    }
    return title
}

function getPath(f, variant, formValues) {
    const {agencyId, agentId, fromDate, toDate} = formValues;
    return `genericDeparturesReport?${stringify({
        selectedId: variant === 'agency' ? agencyId : agentId,
        fromDate: fromDate,
        toDate: (toDate) ? toDate : fromDate,
        report: f,
        variant: variant
    })}`;
}

function getDescString(translate, agencies, agents, variant, formValues) {
    let desc = '';
    const {agencyId, agentId, fromDate, toDate} = formValues;
    const translationProps = {
        fromDate: moment(fromDate).format("DD-MM-YYYY"),
        toDate: moment(toDate).format("DD-MM-YYYY"),
    };
    const translatedRange = (toDate) ? "reports.datesRange" : "reports.oneDate";
    if (variant === 'agency') {
        const agencyIndex = agencies.findIndex(x => x.id === agencyId);
        if (agencies[agencyIndex]) {
            desc = `${agencies[agencyIndex].name} ${translate(translatedRange, translationProps)}`;
        }
    } else {
        const agentIndex = agents.findIndex(x => x.id === agentId);
        if (agents[agentIndex]) {
            desc = `${agents[agentIndex].name} ${translate(translatedRange, translationProps)}`;
        }
    }
    return desc;
}

const DialogExportDeparturesReport = (props) => {
    const {open, file, closeDialog} = props;

    const classes = useExportDialogStyle();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const filename = `reports.files.${file}`;

    const [formState, setFormState] = useState(defaultFormState);
    const {api: {mutators}, values, valid} = formState;
    const {agencyId, toDate} = values;

    const [agencies, setAgencies] = useState([]);
    const [agents, setAgents] = useState([]);
    const [exportVariants, setExportVariants] = useState([]);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (open) {
            moment.locale(localStorage.getItem(LANGUAGE));
            getAgencies(dispatch, dataProvider, setAgencies);
            getExportVariants(dispatch, dataProvider, setExportVariants, file);
        }
        return () => {
            setChecked(false)
        }
    }, [open, file, dispatch, dataProvider]);

    useEffect(() => {
        mutators && mutators.changeToDate([null, !checked && toDate]);
    }, [toDate, checked, mutators]);

    useEffect(() => {
        agencyId && getAgents(dispatch, dataProvider, setAgents, agencyId)
    }, [dispatch, dataProvider, agencyId]);

    useEffect(() => {
        mutators && mutators.changeAgencyId(getFirstElement(agencies, agencies.length > 0));
    }, [agencies, mutators]);

    return (
        <React.Fragment>
            {open &&
            <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
                <KDialogTitle handleClick={closeDialog} title={translate(getTitle(file))}/>
                <DialogContent>
                    <CustomForm
                        mutators={toDateMutator()}
                        onSubmit={noAction}
                        initialValues={initialFormValues}
                        validate={validator(checked, translate)}
                        sharedFormState={setFormState}
                        variant={'standard'}
                        className={classes.form}
                    >
                        <SelectInput
                            className={classes.field}
                            label={translate('dialogExportReport.input.agency')}
                            choices={agencies}
                            translateChoice={false}
                            source={'agencyId'}
                        />
                        <CustomSelectInput
                            className={classes.field}
                            label={translate('dialogExportReport.input.agent')}
                            source={'agentId'}
                            choices={agents}
                            renderMenuFunction={renderMenuItems}
                            subHeaderMenu={agents.length > 10}
                            translateChoice={false}
                            oneItemReadOnly={true}
                        />
                        <DateInputPicker
                            label={translate(`dialogExportReport.input.datesRange.${checked ? 'fromDate' : 'date'}`)}
                            source={'fromDate'}
                            autoOk={true}
                            style={{width: 220}}
                            helperText={<FormControlLabel
                                classes={{label: classes.checkboxLabel}}
                                label={translate('dialogExportReport.input.datesRange.checkbox')}
                                control={<Checkbox
                                    className={classes.checkbox}
                                    value={checked}
                                    defaultChecked={checked}
                                    onChange={(e) => {
                                        mutators.changeToDate([moment(values.fromDate)
                                            .add(1, 'day')
                                            .format("YYYY-MM-DD"), e.target.checked]);
                                        setChecked(e.target.checked)
                                    }}
                                />}
                            />}
                        />
                        <DateInputPicker
                            label={translate('dialogExportReport.input.datesRange.toDate')}
                            source={'toDate'}
                            autoOk={true}
                            style={{width: 220, visibility: (checked) ? 'visible' : 'hidden'}}
                        />
                    </CustomForm>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    {file && exportVariants.map(variant => (variant !== 'agent' || agents.length > 1) && <Download
                        key={variant}
                        label={`dialogExportReport.generate.${variant}`}
                        path={getPath(file, variant, values)}
                        filename={`${translate(filename)}-${getDescString(
                            translate, agencies, agents, variant, values)}.xlsx`}
                        disabled={!(valid) || (variant === 'agent' && !(values.agentId))}
                    />)}
                </DialogActions>
            </Dialog>}
        </React.Fragment>
    );
};

DialogExportDeparturesReport.propTypes = {
    open: PropTypes.bool.isRequired,
    file: PropTypes.string,
    closeDialog: PropTypes.func.isRequired,
};

DialogExportDeparturesReport.defaultProps = {
    open: false
};

export default (DialogExportDeparturesReport);
