import React from "react";
import PropTypes from 'prop-types';
import {Link, TextField} from 'react-admin';
import {makeStyles, Tooltip} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    tooltip: {
        maxWidth: 150
    },
    link: {
        color: theme.palette.primary.main
    },
}));

const TooltipTextField = ({tooltipSource, link, ...props}) => {
    const classes = useStyles();
    if (props.record) {
        const tooltipMessage = props.record[tooltipSource];
        if (link) {
            return <Link
                onClick={(e) => e.stopPropagation()}
                to={link(props.record.id)}
            >
                <Tooltip title={tooltipMessage} classes={{tooltip: classes.tooltip}}>
                    <TextField {...props} className={classes.link}/>
                </Tooltip>
            </Link>
        } else {
            return <Tooltip title={tooltipMessage} classes={{tooltip: classes.tooltip}}>
                <TextField {...props}/>
            </Tooltip>
        }
    } else {
        return <div/>
    }
};

TooltipTextField.propTypes = {
    tooltipSource: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

TooltipTextField.defaultProps = {
    link: false
};

export default TooltipTextField;
