import React, {useEffect, useState} from 'react';
import {useSetLocale, useTranslate} from 'react-admin';
import {Divider, FormLabel, IconButton, Menu, MenuItem, Tooltip} from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountCircle';
import {LANGUAGE} from './authClient'
import {useDispatch, useSelector} from 'react-redux';
import Flag from 'react-flags';
import {changeFontSize} from './actions/theme';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import FontSizeIcon from '@material-ui/icons/FormatSize'
import FontSizeAddIcon from '@material-ui/icons/Add';
import FontSizeRemoveIcon from '@material-ui/icons/Remove';

const languages = [
    {id: 'en-GB', name: 'languageMenu.en'},
    {id: 'it-IT', name: 'languageMenu.it'}
];

const FlagIcon = ({lang}) => {
    if (lang && lang.split('-').length > 1) {
        const flag = lang.split('-')[1];
        return <Flag
            name={flag}
            format="png"
            pngSize={24}
            shiny={true}
            basePath="./flags"
        />
    } else {
        return <Flag
            name={'unknown'}
            format="png"
            pngSize={24}
            shiny={true}
            basePath="./flags"
        />
    }
};

const LoggedMenu = (props) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const fontSize = useSelector(state => state.theme.custom.fontSize);
    const setLocale = useSetLocale();
    const {logout} = props;

    const [open, setOpen] = useState(false);
    const [openLanguage, setOpenLanguage] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    const [activeLanguage, setActiveLanguage] = useState(localStorage.getItem(LANGUAGE));

    function handleRequestChange(e) {
        setOpen(true);
        setAnchorEl(e.currentTarget);
    }

    function handleLanguageRequestChange(e) {
        setOpenLanguage(true);
        setAnchorElLanguage(e.currentTarget);
    }

    function handleTouchTap() {
        setOpen(false);
        setAnchorEl(null);
    }

    function handleLanguageTouchTap() {
        setOpenLanguage(false);
        setAnchorElLanguage(null);
    }

    useEffect(() => {
        dispatch(changeFontSize(fontSize))
    }, [fontSize, dispatch]);

    useEffect(() => {
        async function initializeLanguage() {
            return setLocale(localStorage.getItem(LANGUAGE))
        }

        initializeLanguage();
    }, [setLocale]);


    return (
        <div style={{display: "flex", alignItems: 'center'}}>
            <IconButton
                style={{color: '#fff'}}
                aria-owns={openLanguage ? 'language-appbar' : undefined}
                aria-haspopup="true"
                onClick={(e) => handleLanguageRequestChange(e)}
            >
                <FlagIcon lang={activeLanguage}/>
            </IconButton>
            <Menu
                id="language-appbar"
                anchorEl={anchorElLanguage}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openLanguage}
                onClose={() => handleLanguageTouchTap()}
            >
                {languages.map(lang => {
                        return (
                            <MenuItem
                                key={lang.id}
                                onClick={() => {
                                    setOpenLanguage(false);
                                    setActiveLanguage(lang.id);
                                    localStorage.setItem(LANGUAGE, lang.id);
                                    return setLocale(lang.id)
                                }}
                            >
                                <FlagIcon lang={lang.id}/>
                                &nbsp;
                                {translate(lang.name)}
                            </MenuItem>
                        )
                    }
                )}
            </Menu>
            <IconButton
                style={{color: '#fff'}}
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={(e) => handleRequestChange(e)}
            >
                <AccountIcon/>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => handleTouchTap()}
            >
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <FormLabel style={{margin: '0px 10px'}}>{translate('fontSizeSelector.fontSize')}</FormLabel>
                    <ToggleButtonGroup
                        style={{margin: 10}}
                        onChange={(e, value) => (value) && dispatch(changeFontSize(value))}
                        value={fontSize}
                        exclusive
                        size={"small"}
                    >
                        <ToggleButton value={11}>
                            <React.Fragment>
                                <Tooltip
                                    title={translate('fontSizeSelector.toggleButtons.small', {value: 11})}><FontSizeIcon/></Tooltip>
                                <FontSizeRemoveIcon style={{width: 12, height: 12}}/>
                            </React.Fragment>
                        </ToggleButton>
                        <ToggleButton value={12}>
                            <Tooltip title={translate('fontSizeSelector.toggleButtons.medium', {value: 12})}>
                                <FontSizeIcon/>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value={14}>
                            <React.Fragment>
                                <Tooltip
                                    title={translate('fontSizeSelector.toggleButtons.large', {value: 14})}><FontSizeIcon/></Tooltip>
                                <FontSizeAddIcon style={{width: 12, height: 12}}/>
                            </React.Fragment>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <Divider/>
                {logout}
            </Menu>
        </div>
    );
};

export default LoggedMenu;
