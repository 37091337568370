import React from 'react';
import classnames from 'classnames';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
        input: {
            width: theme.spacing(32)
        }
    }),
    {name: 'CustomFormInput'}
);

const CustomFormInput = ({
                             input,
                             record,
                             variant
                         }) => {

    const classes = useStyles();
    return (
        <div className={classnames('ra-input', `ra-input-${input.props.source}`)}>
            {(React.createElement(input.type, {
                ...{
                    className: classnames(
                        {[classes.input]: !input.props.fullWidth},
                        input.props.className
                    ),
                    record: record,
                    variant: variant
                }, ...input.props
            }))}
        </div>
    )
};

export default CustomFormInput;
