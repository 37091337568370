import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Card, LinearProgress, TextField} from '@material-ui/core';
import {getPieChart, setPieChartFilter} from '../../actions/dashboard';
import ReactHighcharts from 'react-highcharts';
import {withTranslate} from 'react-admin';
import compose from 'recompose/compose'
import {renderMenuItems} from "../../components/CommonUtils";

const filterChoice = [
    {id: 'services', name: 'dashboard.pieChart.filter.services'},
    {id: 'products', name: 'dashboard.pieChart.filter.products'}
];

const chartConfig = {
    title: {
        text: null
    },
    credits: {
        enabled: false
    },
    colors: ['#2196F3', '#4CAF50', '#FFC107', '#FF5722', '#607D8B', '#9C27B0', '#795548', '#673AB7', '#009688', '#E91E63'],
    chart: {
        type: 'pie',
        height: 290
    },
    series: [{
        name: '',
        data: []
    }]
};


const styles = {
    cardContent: {
        padding: 10,
    },
    filterContainer: {
        display: 'inline-block'
    },
    select: {
        width: 'auto',
        minWidth: 150,
        marginLeft: 20,
    },
};

class PieChart extends Component {
    componentDidMount() {
        const {getPieChart, agency, period, type} = this.props;
        getPieChart(agency, period, type);
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const {getPieChart, setPieChartFilter, agency, period, type, isLoading} = this.props;
        const chart = this.refs.chart && this.refs.chart.getChart();
        if (agency !== prevProps.agency || period !== prevProps.period || type !== prevProps.type) {
            if (agency === 2) {
                setPieChartFilter('services')
            }
            getPieChart(agency, period, type);
        } else if (chart && !isLoading) {
            chart.reflow = () => {
            };
        }
    }

    render() {
        const {isLoading, type, data, agency, translate} = this.props;
        return (
            <Card style={{height: '100%'}}>
                <div style={{position: 'relative'}}>
                    {isLoading && <LinearProgress mode="indeterminate" style={{position: 'absolute'}}/>}
                    <div style={styles.cardContent}>
                        <div style={styles.filterContainer}>
                            <TextField
                                select
                                label={''}
                                value={type}
                                margin={'normal'}
                                style={styles.select}
                                disabled={agency === 2}
                                onChange={(e) => this.props.setPieChartFilter(e.target.value)}
                            >
                                {renderMenuItems(filterChoice, false, translate).map(el => el)}
                            </TextField>
                        </div>
                        {(data && data.length > 0) &&
                        <ReactHighcharts config={{
                            ...chartConfig, ...{
                                series: [{
                                    name: translate(`dashboard.pieChart.series.${type}`),
                                    data: data
                                }]
                            }
                        }} ref="chart"/>}
                    </div>

                </div>
            </Card>
        );
    }
}

PieChart.propTypes = {
    agency: PropTypes.number,
    period: PropTypes.number
};

const mapStateToProps = state => {
    let stateToProps = {};
    if (state.dashboard && state.dashboard.pieChart) {
        stateToProps = {
            type: state.dashboard.pieChart.filter,
            isLoading: state.dashboard.pieChart.isLoading,
            data: state.dashboard.pieChart.data
        }
    }
    return stateToProps;
};

const enhance = compose(
    withTranslate,
    connect(mapStateToProps, {getPieChart, setPieChartFilter})
);

export default enhance(PieChart);
