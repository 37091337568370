import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Responsive, showNotification, withTranslate} from 'react-admin';
import {IconedButton} from './'
import DownloadIcon from '@material-ui/icons/GetApp';
import ErrorIcon from '@material-ui/icons/Error';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Tooltip} from '@material-ui/core';

// from http://stackoverflow.com/questions/283956/
function saveAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        document
            .body
            .appendChild(link); //Firefox requires the link to be in the body
        link.download = filename;
        link.href = uri;
        link.click();
        document
            .body
            .removeChild(link); //remove the link when done
    }
}

const SanitizedIcon = ({icon, tooltipTitle}) => (
    <Tooltip title={tooltipTitle}>
        {icon}
    </Tooltip>
);

class DownloadButton extends Component {

    state = {
        loading: false,
        fileData: null
    };

    _onGenerate = () => {
        this.setState({loading: true, fileData: null});
        this.props.genFile(this._donePreparing)
    };

    _donePreparing = (fileData) => {
        if (fileData && fileData.error) {
            this.props.showNotification(fileData.error.message, 'error');
            this.setState({loading: false, fileData: null})
        } else {
            this.setState({loading: false, fileData: fileData})
        }
    };

    _onDownload = () => {
        const fileData = this.props.fileData || (this.props.async
            ? this.state.fileData
            : this.props.genFile());
        if (fileData) {
            const blob = new Blob([fileData.contents], {type: fileData.mime});
            const url = URL.createObjectURL(blob);
            saveAs(url, fileData.filename);
            this.setState({fileData: null});
            this.props.onDownloaded && this.props.onDownloaded()
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.changeElement !== this.props.changeElement) {
            this.setState({fileData: null})
        }
    }

    render() {
        const {
            fileData,
            async,
            showFullTitle,
            downloadTitle,
            loadingTitle,
            generateTitle,
            disabled,
            icon,
            onlyIconButtonStyle,
            tooltipTitle,
            emptyTitle,
            errorIcon,
            translate
        } = this.props;

        const {loading} = this.state;

        if (fileData || !async || this.state.fileData) {
            const title = showFullTitle ? `${downloadTitle} ${this.state.fileData.filename}` : downloadTitle;
            if (this.state.fileData.contents.byteLength > 0) {
                return <Button
                    onClick={this._onDownload}
                    label={title}
                    disabled={disabled}
                >
                    {icon}
                </Button>
            } else {
                return <Button
                    onClick={() => {
                    }}
                    label={translate(emptyTitle)}
                    disabled={true}
                >
                    <Responsive
                        small={<SanitizedIcon
                            icon={errorIcon}
                            tooltipTitle={translate(emptyTitle)}
                        />}
                        medium={errorIcon}
                    />
                </Button>
            }
        } else if (loading) {
            return <IconedButton
                handleClick={() => {
                }}
                buttonType={"downloadButton"}
                label={translate(loadingTitle)}
                disableOnLoad={true}
                disabled={disabled}
            />
        } else {
            return <Button
                onClick={this._onGenerate}
                label={translate(generateTitle)}
                disabled={disabled}
                style={onlyIconButtonStyle}
            >
                <SanitizedIcon
                    icon={icon}
                    tooltipTitle={tooltipTitle}
                />
            </Button>
        }
    }
}

DownloadButton.propTypes = {
    fileData: PropTypes.object,
    genFile: PropTypes.func,
    async: PropTypes.bool,
    generateTitle: PropTypes.string,
    downloadTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    showFullTitle: PropTypes.bool,
    fullWidth: PropTypes.bool,
    loadingTitle: PropTypes.string,
    onDownloaded: PropTypes.func,
    disabled: PropTypes.bool,
    onlyIconButtonStyle: PropTypes.object,
    tooltipTitle: PropTypes.string
};

DownloadButton.defaultProps = {
    async: false,
    showFullTitle: true,
    fullWidth: true,
    disabled: false,
    downloadTitle: 'exportButton.download',
    generateTitle: 'exportButton.generate',
    loadingTitle: 'exportButton.loading',
    emptyTitle: 'exportButton.emptyTitle',
    icon: <DownloadIcon/>,
    errorIcon: <ErrorIcon/>,
    onlyIconButtonStyle: {},
    tooltipTitle: ''
};

const mapDispatchToProps = {
    showNotification,
};

const enhance = compose(
    withTranslate,
    connect(null, mapDispatchToProps),
);

export default enhance(DownloadButton);
