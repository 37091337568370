import {Component} from 'react';
import {connect} from 'react-redux';
import debounce from 'lodash/debounce';
import compose from 'recompose/compose';
import {createSelector} from 'reselect';
import {crudGetManyAccumulate, fetchEnd, fetchStart, getReferenceResource} from 'react-admin';

/**
 * An Input component for choosing a reference record. Useful for foreign keys.
 *
 * This component fetches the possible values in the reference resource
 * (using the fetchClient, and fetchMethod), then delegates rendering
 * to a subcomponent, to which it passes the possible choices
 * as the `choices` attribute.
 *

 * @example
 * <CustomReferenceInput
 *      source="post_id"
 *      reference="posts"
 *      fetchAction={CUSTOM_GET}
 fetchClient={apiClient}
 endpoint="posts"
 *      filterToQuery={searchText => ({ title: searchText, name: myName })}>
 *     <SelectInput optionText="title" />
 * </CustomReferenceInput>
 */
export class CustomReferenceInputController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            isLoading: true,
            options: [],
            warning: "",
            error: ""
        };
        this.debouncedSetFilter = debounce(this.setFilter.bind(this), 100);
    }

    componentDidMount() {
        this.fetchReferenceAndOptions(this.props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((this.props.record || {}).id !== (nextProps.record || {}).id) {
            this.fetchReferenceAndOptions(nextProps);
        } else if (this.props.input.value !== nextProps.input.value) {
            this.fetchReference(nextProps);
        }
    }

    setFilter = filter => {
        if (filter && filter !== this.state.filter) {
            this.setState(
                {filter: filter},
                this.fetchOptions
            );
        } else {
            this.setState(
                {filter: filter, choices: []},
            );
        }
    };

    fetchReference = (props = this.props) => {
        const {crudGetManyAccumulate, input, reference} = props;
        const id = input.value;
        if (id) {
            crudGetManyAccumulate(reference, [id], null, false);
        }
    };

    fetchOptions = (props = this.props) => {
        const {
            fetchClient,
            fetchAction,
            endpoint,
            filterToQuery,
            fetchStart,
            fetchEnd
        } = props;
        const {filter} = this.state;

        const fetchFilter = filterToQuery(filter);
        if (fetchFilter && fetchFilter.search === false) {
            this.setState({
                options: [],
                warning: "",
                error: "",
                isLoading: false,
            });
            return;
        }


        // apiClient(CUSTOM_GET, 'ui_suppliers/codes', fetchFilter)
        //           .then((response) => {
        //               this.setState({
        //                                       options: response.data,
        //                                       warning: "",
        //                                       error: ""
        //                                   })
        //           })


        fetchStart();
        fetchClient(fetchAction, endpoint, fetchFilter)
            .then((response) => {
                this.setState({
                    options: response.data,
                    warning: "",
                    error: ""
                })
            })
            .catch(error => {
                this.setState({
                    options: [],
                    error: "Error retrieving choices"
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
                fetchEnd();
            });

    };

    fetchReferenceAndOptions(props) {
        this.fetchReference(props);
        this.fetchOptions(props);
    }

    render() {
        const {
            referenceRecord,
            input,
            onChange,
            children,
        } = this.props;
        const {filter, options, error, isLoading, warning} = this.state;

        const waiting = !input.value && isLoading;
        const choices = options && options.length ? options : [referenceRecord];
        return children({
            choices: choices,
            error: error,
            isLoading: waiting,
            onChange,
            filter,
            setFilter: this.debouncedSetFilter,
            warning: warning,
        });
    }
}

CustomReferenceInputController.propTypes = {};

CustomReferenceInputController.defaultProps = {
    allowEmpty: false,
    filterToQuery: searchText => ({q: searchText}),
    referenceRecord: null,
};

const makeMapStateToProps = () =>
    createSelector(
        [
            getReferenceResource,
            (_, props) => props.input.value,
        ],
        (referenceState, inputId) => ({
            referenceRecord: referenceState && referenceState.data[inputId],
        })
    );

const EnhancedCustomReferenceInputController = compose(
    connect(
        makeMapStateToProps(),
        {
            crudGetManyAccumulate,
            fetchStart,
            fetchEnd
        }
    )
)(CustomReferenceInputController);

export default EnhancedCustomReferenceInputController;
