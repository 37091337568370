import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput, useTranslate} from 'react-admin';
import {goProductOption} from "../components/CommonUtils";

const Filters = (props) => (
    <Filter {...props} variant="outlined">
        <TextInput source={'q'} alwaysOn resettable/>
    </Filter>
);

export const UiProducts = (props) => {
    const translate = useTranslate();
    return (
        <List
            title={translate('resources.products.name')}
            actions={null}
            filters={<Filters/>}
            bulkActionButtons={false}
            sort={{
                field: 'id',
                order: 'ASC'
            }}
            {...props}
        >
            <Datagrid rowClick={goProductOption}>
                <TextField source={'id'}/>
                <TextField source={'shortDescription'}/>
                <TextField source={'description'}/>
                <BooleanField source={'shelfProduct'}/>
            </Datagrid>
        </List>
    )
};
