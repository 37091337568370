import React, {useEffect, useState} from 'react';
import {fetchEnd, fetchError, fetchStart, useDataProvider, useTranslate} from 'react-admin';
import {Card, CardContent, Grid, makeStyles, MenuItem, TextField} from '@material-ui/core';
import {
    getCompareBarChart,
    getLineChart,
    getPieChart,
    getTicketsBarChart,
    getValuesBarChart,
    setDashboardFilter,
} from '../actions/dashboard';
import {useDispatch, useSelector} from 'react-redux';
import {CompareBarChart, LineChart, PieChart, TicketsBarChart, ValuesBarChart} from "./panels";
import ReactHighcharts from 'react-highcharts';
import {renderMenuItems} from "../components/CommonUtils";

const ReactHighstock = require('react-highcharts/ReactHighstock.src');

const useStyles = makeStyles(theme => ({
    select: {
        minWidth: 150
    },
    panelContainer: {
        flexGrow: 1,
        overflow: 'hidden',
        margin: 10
    },
    panel: {
        maxWidth: 'calc(100vw - 79px)',
        '@media(max-width:959.95px)': {
            maxWidth: 'calc(100vw - 55px)',
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: "100vw"
        },
        minWidth: 400
    },
}));

const resize = () => {
    if (ReactHighcharts.Highcharts.charts.length > 0) {
        ReactHighcharts.Highcharts.charts.forEach(chart => {
            if (chart) {
                chart.setSize(null, null, true);
            }
        })
    }
    if (ReactHighstock.Highcharts.charts.length > 0) {
        ReactHighstock.Highcharts.charts.forEach(chart => {
            if (chart) {
                chart.setSize(null, null, true)
            }
        })
    }
};

const Dashboard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const viewVersion = useSelector(state => state.admin.ui.viewVersion);
    const selectedAgency = useSelector(state => state.dashboard.filter.agency);
    const selectedPeriod = useSelector(state => state.dashboard.filter.period);
    const pieChartFilter = useSelector(state => state.dashboard.pieChart.filter);
    const ticketsCompareChartFilters = useSelector(state => state.dashboard.compareChart.tickets.filters);
    const valuesCompareChartFilters = useSelector(state => state.dashboard.compareChart.values.filters);
    const [agencies, setAgencies] = useState([]);

    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, []);

    useEffect(() => {
        dispatch(fetchStart());
        dataProvider.customGet('agencies', {})
            .then(response => {
                setAgencies(response.data)
            })
            .catch((e) => {
                if (e.status === 401 || e.status === 403) {
                    dispatch(fetchError());
                }
            })
            .finally(() => {
                dispatch(fetchEnd());
            })
    }, [dispatch, dataProvider]);

    useEffect(() => {
        if (viewVersion > 0) {
            dispatch(getTicketsBarChart(selectedAgency, selectedPeriod));
            dispatch(getValuesBarChart(selectedAgency, selectedPeriod));
            dispatch(getLineChart(selectedAgency, selectedPeriod));
        }
    }, [viewVersion, dispatch, selectedAgency, selectedPeriod]);

    useEffect(() => {
        if (viewVersion > 0) {
            dispatch(getPieChart(selectedAgency, selectedPeriod, pieChartFilter));
        }
    }, [viewVersion, dispatch, selectedAgency, selectedPeriod, pieChartFilter]);

    useEffect(() => {
        if (viewVersion > 0) {
            dispatch(getCompareBarChart(
                "tickets",
                ticketsCompareChartFilters.yearA,
                ticketsCompareChartFilters.yearB,
                ticketsCompareChartFilters.monthA,
                ticketsCompareChartFilters.monthB,
            ));
        }
    }, [viewVersion, dispatch, ticketsCompareChartFilters]);

    useEffect(() => {
        if (viewVersion > 0) {
            dispatch(getCompareBarChart(
                "values",
                valuesCompareChartFilters.yearA,
                valuesCompareChartFilters.yearB,
                valuesCompareChartFilters.monthA,
                valuesCompareChartFilters.monthB,
            ));
        }
    }, [viewVersion, dispatch, valuesCompareChartFilters]);

    const changeAgencyFilter = (e) => {
        dispatch(setDashboardFilter(e.target.value, selectedPeriod));
    };

    const changePeriodFilter = (e) => {
        dispatch(setDashboardFilter(selectedAgency, e.target.value));
    };

    return (
        <React.Fragment>
            <Card style={{margin: '0px 10px', marginTop: 16}}>
                <CardContent style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <TextField
                            select
                            label={translate('dashboard.filter.agency.name')}
                            className={classes.select}
                            value={(selectedAgency) ? selectedAgency : ''}
                            onChange={changeAgencyFilter}
                        >
                            <MenuItem value={0}/>
                            {renderMenuItems(agencies, false).map(el => el)}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            select
                            label={translate('dashboard.filter.period.name')}
                            className={classes.select}
                            value={selectedPeriod}
                            onChange={changePeriodFilter}
                        >
                            <MenuItem key={24} value={24}>{translate('dashboard.filter.period.day')}</MenuItem>
                            <MenuItem key={168} value={168}>{translate('dashboard.filter.period.week')}</MenuItem>
                            <MenuItem key={720} value={720}>{translate('dashboard.filter.period.month')}</MenuItem>
                        </TextField>
                    </div>
                </CardContent>
            </Card>
            <div className={classes.panelContainer}>
                <Grid container spacing={4}>
                    <Grid item xs className={classes.panel}>
                        <TicketsBarChart agency={selectedAgency} period={selectedPeriod}/>
                    </Grid>
                    <Grid item xs className={classes.panel}>
                        <ValuesBarChart agency={selectedAgency} period={selectedPeriod}/>
                    </Grid>
                    <Grid item xs className={classes.panel}>
                        <PieChart agency={selectedAgency} period={selectedPeriod}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} className={classes.panel}>
                        <LineChart agency={selectedAgency} period={selectedPeriod}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs className={classes.panel}>
                        <CompareBarChart type={"tickets"}/>
                    </Grid>
                    <Grid item xs className={classes.panel}>
                        <CompareBarChart type={"values"}/>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
