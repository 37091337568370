import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-admin';
import IconAction from '@material-ui/icons/List';
import {NavLink} from 'react-router-dom'


const LinkButton = ({style, label, to}) => {
    return (
        <NavLink to={to}>
            <Button style={style}
                    label={label}>
                <IconAction/>
            </Button>
        </NavLink>
    );
};

LinkButton.propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

LinkButton.defaultProps = {};

export default LinkButton;
