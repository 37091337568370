import React from 'react';
import {Layout} from 'react-admin';
import {useSelector} from 'react-redux';
import CustomMenu from './CustomMenu';
import LoggedMenu from './loggedMenu';
import {TaBrokerTheme} from './tabrokerTheme';
import TaBrokerAppBar from './TabrokerAppBar';
import TaBrokerSidebar from './TaBrokerSidebar';

const AppBar = (props) => <TaBrokerAppBar {...props} userMenu={<LoggedMenu/>}/>;
const CustomLayout = (props) => {
    const fontSize = useSelector(state => state.theme.custom.fontSize);
    return (<Layout {...props} menu={CustomMenu} appBar={AppBar}
                    theme={TaBrokerTheme(fontSize)} sidebar={TaBrokerSidebar}/>);
};

export default (CustomLayout);
