import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    refreshView,
    setListSelectedIds as setSelectedIds,
    showNotification,
    useDataProvider,
    useTranslate
} from 'react-admin';
import {NotifyConfirm} from "../components";
import {IconedButton} from "./buttons";

const BulkCancelBookingsButton = (props) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);

    const handleDialogClose = () => {
        setOpen(false);
        dispatch(setSelectedIds(props.resource, []));
    };

    const handleDialogOpen = () => {
        setOpen(true)
    };

    function handleAction() {
        const params = {
            bookingIds: props.selectedIds,
        };


        dispatch(fetchStart());
        dataProvider.customPost('bookings/cancel', params)
            .then((response) => {
                if (response.data.status === 'OK') {
                    setOpen(false);
                    dispatch(setSelectedIds(props.resource, []));
                    dispatch(showNotification('bookings.cancelBooking.response', 'info', {messageArgs: {smart_count: params.bookingIds.length}}));
                    dispatch(refreshView());
                }
            })
            .catch((error) => {
                dispatch(showNotification(error.message, 'error'));
            })
            .finally(() => {
                dispatch(fetchEnd());
            });
    }

    return (
        <React.Fragment>
            <IconedButton
                buttonType={"deleteButton"}
                label={translate('bookings.cancelBooking.buttonLabel', {smart_count: props.selectedIds.length})}
                handleClick={handleDialogOpen}
            />
            {open && <NotifyConfirm
                isOpen={open}
                title={translate('bookings.cancelBooking.title', {smart_count: props.selectedIds.length})}
                content={translate('bookings.cancelBooking.content', {smart_count: props.selectedIds.length})}
                onConfirm={handleAction}
                onClose={handleDialogClose}
                disableOnLoad={true}
            />}
        </React.Fragment>)
};

BulkCancelBookingsButton.propTypes = {
    selectedIds: PropTypes.array,
    setSelectedIds: PropTypes.func,
};

BulkCancelBookingsButton.defaultProps = {
    selectedIds: [],
};

export default (BulkCancelBookingsButton);
