import React from 'react';
import {Admin, fetchUtils, Resource} from 'react-admin';
import dataClient from './dataClient';
import authClient, {setRequestSessionHeaders} from './authClient';
import CustomLayout from './CustomLayout';
import {UiBookings, UiProductOptions, UiProducts, UiReports, UiServices, UiServiceTrips} from './resources';
import BookingsIcon from '@material-ui/icons/Wc';
import ServicesIcon from '@material-ui/icons/DirectionsBus';
import ProductsIcon from '@material-ui/icons/LocalMall';
import ReportsIcon from '@material-ui/icons/Archive';
import {i18nProvider} from './i18n/i18nProvider';
import themeReducer from './reducer/theme';
import Login from './Login';
import Dashboard from './dashboard/Dashboard';
import dashboardReducer from './reducer/dashboard';
import dashboardSaga from './saga/dashboardSaga';

export const baseApiUrl = process.env.REACT_APP_API_PROTOCOL && process.env.REACT_APP_API_ADDRESS && process.env.REACT_APP_API_PORT
    ? `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_ADDRESS}:${process.env.REACT_APP_API_PORT}/v1/ui/tabroker-admin`
    : `/v1/ui/tabroker-admin`;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
        options.headers.set('Content-Type', 'application/json');
        options.headers.set("Cache-Control", "no-cache, no-store, must-revalidate");
    }
    setRequestSessionHeaders(options.headers);
    return fetchUtils.fetchJson(url, options);
};

export const apiClient = dataClient(baseApiUrl, httpClient);
const App = () =>
    (
        <Admin
            authProvider={authClient}
            dataProvider={apiClient}
            i18nProvider={i18nProvider}
            layout={CustomLayout}
            customReducers={{theme: themeReducer, dashboard: dashboardReducer}}
            loginPage={Login}
            dashboard={Dashboard}
            customSagas={dashboardSaga(apiClient)}
        >
            <Resource
                name={'services'}
                icon={ServicesIcon}
                list={UiServices}
                hasShow={false}
            />
            <Resource
                name={'service-trips'}
                options={{hideMenu: true}}
                list={UiServiceTrips}
                hasShow={false}
            />
            <Resource
                name={'products'}
                icon={ProductsIcon}
                list={UiProducts}
                hasShow={false}
            />
            <Resource
                name={'product-options'}
                options={{hideMenu: true}}
                list={UiProductOptions}
                hasShow={false}
            />
            <Resource
                name="bookings"
                icon={BookingsIcon}
                list={UiBookings}
                hasShow={false}
            />
            <Resource
                name="reports"
                icon={ReportsIcon}
                list={UiReports}
                hasShow={false}
            />
        </Admin>
    );

export default App;
