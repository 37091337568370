import React from "react";
import {FormSpy} from 'react-final-form';

const FormStateProvider = ({
                               setStateAction,
                               submitAction,
                               formApi
                           }) => (
    <FormSpy
        onChange={(formValues) => {
            setStateAction({
                ...formValues,
                ...{submit: submitAction, api: formApi}
            })
        }}
    />
);

export default FormStateProvider;
