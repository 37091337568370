import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {DownloadButton} from './buttons';
import {fetchEnd, fetchError, fetchStart, showNotification as showNotificationAction, withTranslate} from 'react-admin';
import {stringify} from 'query-string';
import {baseApiUrl} from '../App';
import {SESSION_TOKEN} from '../authClient';
import axiosClient from "axios";
import compose from 'recompose/compose';
import pure from 'recompose/pure';

const getFileName = (related, record, resource, path) => {
    let filename;
    if (related) {
        filename = `${record.name}.xlsx`;
    } else {
        filename = resource ?
            `${resource}.xlsx`
            : `${path.substr(0, path.indexOf('/'))}.xlsx`
    }
    return filename;
};

class Download extends Component {

    download = (done) => {
        const {record, resource, showNotification, related, filterValues, path, fetchStart, fetchEnd, fetchError, translate} = this.props;
        let query = {};
        let url = `${baseApiUrl}`;

        if (path) {
            url = `${url}/${path}`;
        } else {
            query = {resource: resource};
            filterValues && Object.keys(filterValues).forEach(key => {
                query[(key)] = filterValues[key];
            });
            query = related ? {
                ...query,
                related: related,
                id: record.id
            } : query;
            url = `${url}?${stringify(query)}`;
        }

        const requestSessionHeaders = {
            'Authorization': `Bearer ${localStorage.getItem(SESSION_TOKEN)}`,
        };
        const axiosCfg = {
            url: url,
            method: 'get',
            headers: {
                ...requestSessionHeaders
            },
            responseType: 'arraybuffer'
        };

        const client = axiosClient.create({});
        fetchStart();
        client(axiosCfg)
            .then(response => {
                let filename;
                if (this.props.filename) {
                    filename = this.props.filename;
                } else {
                    filename = getFileName(related, record, resource, path);
                }
                done({
                    filename: filename,
                    contents: response.data
                })
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    fetchError()
                }
                showNotification(translate('exportButton.error'), 'warning');
                done(null)
            })
            .finally(() => {
                fetchEnd()
            })

    };

    render() {
        const {label} = this.props;
        return (
            <DownloadButton
                generateTitle={label}
                loadingTitle="exportButton.preparing"
                showFullTitle={false}
                async={true}
                changeElement={this.props.path}
                genFile={this.download}
                disabled={this.props.disabled}
            />
        );
    }
}

Download.propTypes = {
    resource: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
};

Download.defaultProps = {
    label: "Export",
    disabled: false,
    renderedResource: null
};

const mapStateToProps = (state, props) => {
    if (props.resource) {
        return ({
            disabled: state.admin.resources[props.resource].list.ids.length <= 0
        })
    } else {
        return {}
    }
};

const mapDispatchToProps = {
    showNotification: showNotificationAction,
    fetchStart,
    fetchEnd,
    fetchError
};

const enhance = compose(
    withTranslate,
    connect(mapStateToProps, mapDispatchToProps),
    pure
);

export default enhance(Download);
