import * as tabrokerItalianMessages from './locales/it-IT';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const defaultMessages = {
    it: {...tabrokerItalianMessages},
};

const asyncMessages = {
    'en-GB': () => import('./locales/en-GB').then(messages => messages),
    'it-IT': () => import('./locales/it-IT').then(messages => messages)
};

export const i18nProvider = polyglotI18nProvider(locale => {
    if (Object.keys(asyncMessages).includes(locale)) {
        return asyncMessages[locale]();
    } else {
        return defaultMessages['it'];
    }
}, 'it', {allowMissing: true});
