import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-admin';
import {useSelector} from 'react-redux';
import {CircularProgress} from '@material-ui/core';
import IconDelete from '@material-ui/icons/Delete';
import IconClose from '@material-ui/icons/Close';
import IconAdd from '@material-ui/icons/Add';
import IconArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import IconList from '@material-ui/icons/List';
import IconDownload from '@material-ui/icons/GetApp';
import IconCalendar from '@material-ui/icons/Today';
import IconReport from '@material-ui/icons/CloudDownload';
import IconOk from '@material-ui/icons/CheckCircle';

const isDisabled = (disabled, disableOnLoad, isLoadign) => {
    if (disabled) {
        return true;
    }
    return disableOnLoad ? isLoadign : false;
};

const IconedButton = (props) => {
    const isLoading = useSelector(state => state.admin.loading > 0);
    const {style, label, disabled, disableOnLoad, handleClick, buttonType, ...rest} = props;
    return (
        <Button style={style}
                label={label}
                disabled={isDisabled(disabled, disableOnLoad, isLoading)}
                onClick={(e) => {
                    e.stopPropagation();
                    handleClick(rest)
                }}>
            {
                ((disableOnLoad && isLoading) ? (<CircularProgress size={18}/>) :
                    ({
                        listButton: <IconList/>,
                        closeButton: <IconClose/>,
                        deleteButton: <IconDelete/>,
                        addButton: <IconAdd/>,
                        nextButton: <IconArrowRight/>,
                        prevButton: <IconArrowLeft/>,
                        saveButton: <IconSave/>,
                        cancelButton: <IconCancel/>,
                        downloadButton: <IconDownload/>,
                        calendarButton: <IconCalendar/>,
                        reportButton: <IconReport/>,
                        okButton: <IconOk/>
                    })[buttonType])
            }
        </Button>
    )
};

IconedButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    disableOnLoad: PropTypes.bool,
    style: PropTypes.object,
    buttonType: PropTypes.string.isRequired,
};

IconedButton.defaultProps = {
    label: null,
    disabled: false,
    buttonType: '',
    disableOnLoad: false,
};

export default IconedButton;
