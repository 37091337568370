import React from 'react';
import {stringify} from 'query-string';
import {fetchEnd, fetchError, fetchStart, showNotification} from "react-admin";
import {ListSubheader, makeStyles, MenuItem} from '@material-ui/core';
import moment from "moment";
import {camelCase} from 'lodash';

const getCurrencySymbol = (currencyCode) => {
    const options = {
        style: 'currency',
        currency: currencyCode
    };
    const a = 0;
    return a.toLocaleString("en", options).replace("0.00", "");
};

const formatCurrencyString = (value, symbol, decimalDigits) => {
    return `${symbol} ${parseFloat(value).toFixed(decimalDigits)}`;
};

export const FormatCurrency = (value, decimalDigits, currencyCode) => {
    if (currencyCode) {
        const symbol = getCurrencySymbol(currencyCode);
        return formatCurrencyString(value, symbol, decimalDigits);
    } else {
        return value;
    }
};

export const DynamicSuggestLimit = (search) => {
    let limit;
    if (isNaN(search)) {
        limit = search.length * 20;
    } else {
        // is a number
        limit = search.length * 100;
    }
    return limit;
};

export const goServiceTrips = (serviceId) => {
    return `/service-trips?${stringify({
        filter: JSON.stringify({
            'serviceId': serviceId,
            'direction': 1
        })
    })}&order=ASC&page=1&perPage=10&sort=id`;
};

export const goProductOption = (id) => {
    return `/product-options?${stringify({
        filter: JSON.stringify({
            'productId': id,
            'direction': 1
        })
    })}&order=ASC&page=1&perPage=10&sort=id`;
};

export const catchError = (e, dispatch) => {
    if (e.status === 401 || e.status === 403) {
        dispatch(fetchError());
    }
    dispatch(showNotification(e.message, 'warning'));
};

export const getExportVariants = (dispatch, dataProvider, setStateAction, file) => {
    dispatch(fetchStart());
    dataProvider.customGet(`report/${file}/variants`, {})
        .then(response => {
            setStateAction(response.data)
        })
        .catch((e) => catchError(e, dispatch))
        .finally(() => {
            dispatch(fetchEnd());
        })
};

export const getAgencies = (dispatch, dataProvider, setStateAction, filter = null) => {
    dispatch(fetchStart());
    dataProvider.customGet('agencies', {})
        .then(response => {
            if (!filter) {
                setStateAction(response.data);
            } else {
                setStateAction(response.data.filter(filter))
            }
        })
        .catch((e) => catchError(e, dispatch))
        .finally(() => {
            dispatch(fetchEnd());
        })
};

export const getAvailableYears = (dispatch, dataProvider, setStateAction) => {
    dispatch(fetchStart());
    dataProvider.customGet('bookings/years', {})
        .then(response => {
            const years = response.data;
            if (!response.data.includes(moment().year())) {
                years.push(moment().year());
            }
            setStateAction(years.map(y => ({id: y, name: y})))
        })
        .catch((e) => catchError(e, dispatch))
        .finally(() => {
            dispatch(fetchEnd());
        })
};

export const getAgents = (dispatch, dataProvider, setStateAction, agencyId) => {
    dispatch(fetchStart());
    dataProvider.customGet(`agencies/${agencyId}/users`, {})
        .then(response => {
            setStateAction(response.data)
        })
        .catch((e) => catchError(e, dispatch))
        .finally(() => {
            dispatch(fetchEnd());
        })
};

export const renderMenuItems = (elements, haveSubheader, translate = null) => {
    const items = [];
    const groups = [];
    if (haveSubheader) {
        elements.forEach(el => {
            if (!groups.includes(el.name[0].toUpperCase())) {
                groups.push(el.name[0].toUpperCase())
            }
        });
        groups.forEach(group => {
            items.push(<ListSubheader style={{lineHeight: '36px', background: '#fff'}}
                                      key={group}>{group}</ListSubheader>);
            elements.filter(x => x.name[0].toUpperCase() === group).forEach(el => {
                items.push(<MenuItem key={el.id} value={el.id}>{(translate) ? translate(el.name) : el.name}</MenuItem>)
            })
        })
    } else {
        elements.forEach(el => {
            items.push(<MenuItem key={el.id} value={el.id}>{(translate) ? translate(el.name) : el.name}</MenuItem>)
        })
    }
    return items;
};

export const getFirstElement = (list, condition) => {
    let firstElement = null;
    if (list && condition) {
        firstElement = list[0].id;
    }
    return firstElement;
};

export const noAction = () => {
};

export const mutatorActions = (fields) => {
    const actions = {};
    fields.forEach(field => {
        actions[camelCase(`change-${field}`)] = ([value], state, utils) => {
            if (value !== state.formState.values[field]) {
                utils.changeValue(state, field, () => value)
            }
        }
    });
    return actions;
};

export const useExportDialogStyle = makeStyles({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    actions: {
        flexWrap: 'wrap'
    },
    field: {
        width: 220
    },
    checkboxLabel: {
        marginLeft: 5,
        fontSize: '0.75rem'
    },
    checkbox: {
        marginLeft: 9,
        padding: 0
    }
});

export const defaultFormState = {
    values: {},
    api: {},
    submit: null,
    valid: false,
    other: {}
};
