import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {makeStyles, MenuItem, Typography} from '@material-ui/core';
import {
    FieldTitle,
    InputHelperText,
    Labeled,
    ResettableTextField,
    useChoices,
    useInput,
    useTranslate
} from 'react-admin';


const sanitizeInputRestProps = ({
                                    addLabel,
                                    allowEmpty,
                                    alwaysOn,
                                    emptyValue,
                                    basePath,
                                    choices,
                                    className,
                                    component,
                                    crudGetMatching,
                                    crudGetOne,
                                    defaultValue,
                                    filter,
                                    filterToQuery,
                                    formClassName,
                                    initializeForm,
                                    input,
                                    isRequired,
                                    label,
                                    locale,
                                    meta,
                                    onChange,
                                    options,
                                    optionValue,
                                    optionText,
                                    disableValue,
                                    perPage,
                                    record,
                                    reference,
                                    resource,
                                    setFilter,
                                    setPagination,
                                    setSort,
                                    sort,
                                    source,
                                    textAlign,
                                    translate,
                                    translateChoice,
                                    validation,
                                    ...rest
                                }) => rest;

const sanitizeFieldRestProps = ({
                                    variant,
                                    addLabel,
                                    allowEmpty,
                                    basePath,
                                    cellClassName,
                                    className,
                                    formClassName,
                                    headerClassName,
                                    label,
                                    linkType,
                                    link,
                                    locale,
                                    record,
                                    resource,
                                    sortable,
                                    sortBy,
                                    source,
                                    textAlign,
                                    translateChoice,
                                    ...rest
                                }) => rest;

const useStyles = makeStyles(
    theme => ({
        input: {
            minWidth: theme.spacing(20),
        },
    }),
    {name: 'CustomSelectInput'}
);

const CustomSelectInput = ({
                               allowEmpty,
                               choices = [],
                               className,
                               disableValue,
                               emptyText,
                               emptyValue,
                               format,
                               helperText,
                               label,
                               onBlur,
                               onChange,
                               onFocus,
                               options,
                               optionText,
                               optionValue,
                               parse,
                               resource,
                               source,
                               translateChoice,
                               validate,
                               renderMenuFunction,
                               subHeaderMenu,
                               oneItemReadOnly,
                               ...rest
                           }) => {
    const translate = useTranslate();
    const classes = useStyles({});
    const {getChoiceText, getChoiceValue} = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });

    const {
        id,
        input,
        isRequired,
        meta: {error, touched},
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const renderEmptyItemOption = useCallback(() => {
        return React.isValidElement(emptyText)
            ? React.cloneElement(emptyText)
            : translate(emptyText, {_: emptyText});
    }, [emptyText, translate]);

    const renderMenuItemOption = useCallback(choice => getChoiceText(choice), [
        getChoiceText,
    ]);

    const translateCustomOptions = () => (
        translateChoice ? translate : null
    );

    const getEmptyOption = () => (
        allowEmpty ? (
            <MenuItem
                value={emptyValue}
                key="null"
                aria-label={translate('ra.action.clear_input_value')}
                title={translate('ra.action.clear_input_value')}
            >
                {renderEmptyItemOption()}
            </MenuItem>
        ) : null
    );

    const getMenu = () => (
        renderMenuFunction ?
            renderMenuFunction(choices, subHeaderMenu, translateCustomOptions()).map(el => el) :
            choices.map(choice => (
                <MenuItem
                    key={getChoiceValue(choice)}
                    value={getChoiceValue(choice)}
                    disabled={get(choice, disableValue)}
                >
                    {renderMenuItemOption(choice)}
                </MenuItem>
            ))
    );

    return (
        (oneItemReadOnly && choices.length === 1) ?
            <Labeled
                className={className}
                record={rest.record}
                resource={resource}
                label={label}
                source={source}
                disabled={false}
            >
                <Typography
                    component="span"
                    variant="body1"
                    className={className}
                    {...sanitizeFieldRestProps(rest)}
                >
                    {getChoiceText(choices[0])}
                </Typography>
            </Labeled> :
            <ResettableTextField
                id={id}
                {...input}
                select
                label={
                    label !== '' &&
                    label !== false && (
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    )
                }
                className={`${classes.input} ${className}`}
                clearAlwaysVisible
                error={!!(touched && error)}
                helperText={
                    <InputHelperText
                        touched={touched}
                        error={error}
                        helperText={helperText}
                    />
                }
                {...options}
                {...sanitizeInputRestProps(rest)}
            >
                {getEmptyOption()}
                {getMenu()}
            </ResettableTextField>
    );
};

CustomSelectInput.propTypes = {
    allowEmpty: PropTypes.bool.isRequired,
    emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    emptyValue: PropTypes.any,
    choices: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
    ]).isRequired,
    optionValue: PropTypes.string.isRequired,
    disableValue: PropTypes.string,
    resource: PropTypes.string,
    source: PropTypes.string,
    translateChoice: PropTypes.bool,
    renderMenuFunction: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.func]),
    subHeaderMenu: PropTypes.bool,
    oneItemReadOnly: PropTypes.bool
};

CustomSelectInput.defaultProps = {
    allowEmpty: false,
    emptyText: '',
    emptyValue: '',
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
    disableValue: 'disabled',
    renderMenuFunction: null,
    subHeaderMenu: false,
    oneItemReadOnly: false
};

export default CustomSelectInput;
