import React, {useState} from 'react';
import {Dialog, DialogContent, DialogContentText, makeStyles, TextField} from '@material-ui/core';
import {fetchEnd, fetchError, fetchStart, useDataProvider, useTranslate} from 'react-admin'
import {Calendar, KDialogTitle} from './';
import {IconedButton} from './buttons';
import {range} from 'lodash';
import {useDispatch} from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles({
    container: {
        marginTop: 50,
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fill, minmax(360px, 1fr))',
        gridTemplateRows: '350px 350px 350px'
    },
    description: {
        padding: 10,
        color: 'black'
    }
});

const DialogTripCalendar = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const [open, setOpen] = useState(false);
    const [year, setYear] = useState(moment().year());
    const [calendarDays, setCalendarDays] = useState([]);

    function getCalendarDays() {
        dispatch(fetchStart());
        dataProvider.customGet('calendarDays', {calendarId: props.record.calendarId})
            .then(response => {
                setCalendarDays(response.data.calendarDays);
            })
            .catch((e) => {
                if (e.status === 401 || e.status === 403) {
                    dispatch(fetchError());
                }
            })
            .finally(() => {
                dispatch(fetchEnd());
            })
    }

    function getTitle() {
        if (props.record) {
            const {id} = props.record;
            return translate('dialogCalendar.recordTitle', {id: id})
        } else {
            return translate('dialogCalendar.noRecordTitle')
        }
    }

    const handleOpen = () => {
        getCalendarDays();
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const YearSelection = () => {
        return <div style={{textAlign: 'center'}}>
            <br/>
            <IconedButton handleClick={() => setYear(year - 1)} buttonType={'prevButton'}/>
            <TextField value={year} InputProps={{disableUnderline: true}}
                       inputProps={{style: {textAlign: 'center'}}} style={{width: 80}}/>
            <IconedButton handleClick={() => setYear(year + 1)} buttonType={'nextButton'}/>
            <br/>
        </div>
    };

    const {origin, destination, departureTime, arrivalTime} = props.record;
    return (
        <React.Fragment>
            <IconedButton
                handleClick={handleOpen}
                label={translate('dialogCalendar.buttonLabel')}
                buttonType={'calendarButton'}
            />
            {open &&
            <Dialog open={open} fullWidth={true} maxWidth={false}>
                <KDialogTitle handleClick={handleClose} title={getTitle()}/>
                <DialogContentText className={classes.description}>
                    {
                        translate('dialogCalendar.description', {
                            origin: origin,
                            departureTime: departureTime,
                            destination: destination,
                            arrivalTime: arrivalTime
                        })
                    }
                </DialogContentText>
                <DialogContent>
                    <YearSelection/>
                    <div className={classes.container}>
                        {range(0, 12).map(i => React.createElement(Calendar, {
                            key: i,
                            calendarDays: calendarDays,
                            month: i,
                            year: year
                        }))}
                    </div>
                </DialogContent>
            </Dialog>}
        </React.Fragment>
    );
};

export default (DialogTripCalendar);
