import React, {useEffect, useState} from 'react';
import {fetchEnd, fetchError, fetchStart, showNotification, Title, useDataProvider, useTranslate} from 'react-admin';
import {Card, CardContent, CardMedia, makeStyles, Typography} from '@material-ui/core';
import NoReportAvailableIcon from '@material-ui/icons/CloudOff';
import {useDispatch, useSelector} from 'react-redux';
import {DialogExportBookingsReport, DialogExportDeparturesReport, DialogExportReport} from "../components";

const useStyles = makeStyles({
    card: {
        width: '250px !important',
        height: '150px !important',
        border: '1px solid lightgrey',
        margin: '20px',
        float: 'left',
        cursor: 'pointer'
    },
    cardBody: {
        display: 'flex',
        height: 'calc(100% - 80px)',
        whiteSpace: 'break-spaces',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        textAlign: 'center',
        '&:last-child': {
            paddingBottom: 0
        }
    },
    noReportsPanel: {
        width: '100%',
        marginTop: '20vh',
        textAlign: 'center'
    },
    noReportsIcon: {
        width: 100,
        height: 100
    },
    media: {
        height: 80,
        backgroundSize: 'contain',
        backgroundColor: 'rgba(122,160,190,0.45)',
    },
});

const getDialogByReport = (open, file, closeAction) => {
    if (file === 'bookings') {
        return <DialogExportBookingsReport open={open} file={file} closeDialog={closeAction}/>
    } else {
        return (file === 'departures') ?
            <DialogExportDeparturesReport open={open} file={file} closeDialog={closeAction}/> :
            <DialogExportReport open={open} file={file} closeDialog={closeAction}/>
    }
};

export const UiReports = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const viewVersion = useSelector(state => state.admin.ui.viewVersion);

    const [open, setOpen] = useState(false);
    const [expFile, setExpFile] = useState(null);
    const [availableExports, setAvailableExports] = useState([]);
    const [logos, setLogos] = useState([]);

    async function getLogos(agencies) {
        const temp = {};
        for (let i = 0; i < agencies.length; i++) {
            try {
                temp[agencies[i]] = await import(`../backgrounds/${agencies[i]}.png`).then(logo => logo.default);
            } catch (e) {
                temp[agencies[i]] = await import('../backgrounds/noAgencyLogo.png').then(logo => logo.default)
            }
        }
        setLogos(temp)
    }

    useEffect(() => {
        function getAvailableExports() {
            dispatch(fetchStart());
            dataProvider.customGet('report/available-exports', {})
                .then(response => {
                    const exportsForAgency = Object.keys(response.data).map(a => ({
                        agency: a,
                        exports: response.data[a]
                    }));
                    setAvailableExports(exportsForAgency);
                })
                .catch(e => {
                    if (e.status === 401 || e.status === 403) {
                        dispatch(fetchError());
                    }
                    dispatch(showNotification(e.message, 'warning'));
                })
                .finally(() => {
                    dispatch(fetchEnd());
                })
        }

        getAvailableExports();
    }, [viewVersion, dataProvider, dispatch]);

    useEffect(() => {
        getLogos(availableExports.map(a => a.agency));
    }, [availableExports]);


    const handleDialogOpen = (exp) => {
        setOpen(true);
        setExpFile(exp);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    if (availableExports && availableExports.length > 0) {
        return <React.Fragment>
            <Title title={translate('resources.reports.name')}/>
            <div>
                {availableExports.map(agencyExports =>
                    agencyExports.exports.map(exp =>
                        <Card key={exp} className={classes.card} onClick={() => handleDialogOpen(exp)}>
                            {logos[agencyExports.agency] &&
                            <CardMedia className={classes.media}
                                       image={logos[agencyExports.agency]}/>}
                            <CardContent className={classes.cardBody}>
                                <Typography variant={"h6"} color={'secondary'}>
                                    {translate(`reports.files.${exp}`)}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
            </div>
            {getDialogByReport(open, expFile, handleDialogClose)}
        </React.Fragment>
    } else {
        return <div className={classes.noReportsPanel}>
            <NoReportAvailableIcon className={classes.noReportsIcon}/>
            <Typography variant={'h6'}>{translate('reports.noReports')}</Typography>
        </div>
    }
};
