import {
    GET_COMPARE_BAR_CHART_DATA,
    GET_COMPARE_BAR_CHART_DATA_ERROR,
    GET_COMPARE_BAR_CHART_DATA_SUCCESS,
    GET_LINE_CHART_DATA,
    GET_LINE_CHART_DATA_ERROR,
    GET_LINE_CHART_DATA_SUCCESS,
    GET_PIE_CHART_DATA,
    GET_PIE_CHART_DATA_ERROR,
    GET_PIE_CHART_DATA_SUCCESS,
    GET_TICKETS_BAR_CHART_DATA,
    GET_TICKETS_BAR_CHART_DATA_ERROR,
    GET_TICKETS_BAR_CHART_DATA_SUCCESS,
    GET_VALUES_BAR_CHART_DATA,
    GET_VALUES_BAR_CHART_DATA_ERROR,
    GET_VALUES_BAR_CHART_DATA_SUCCESS,
    SET_COMPARE_BAR_CHART_FILTER,
    SET_DASHBOARD_FILTER,
    SET_PIE_CHART_FILTER
} from '../actions/dashboard';
import moment from 'moment';

const initialState = {
    filter: {
        agency: 0,
        period: 720
    },
    barChart: {
        tickets: {
            isLoading: null,
            data: null,
        },
        values: {
            isLoading: null,
            data: null,
        }
    },
    pieChart: {
        isLoading: null,
        filter: 'services',
        data: null
    },
    lineChart: {
        isLoading: null,
        data: null
    },
    compareChart: {
        tickets: {
            filters: {
                yearA: moment().subtract(1, 'year').year(),
                yearB: moment().year(),
                monthA: moment().month() + 1,
                monthB: moment().month() + 1
            },
            isLoading: false,
            data: null
        },
        values: {
            filters: {
                yearA: moment().subtract(1, 'year').year(),
                yearB: moment().year(),
                monthA: moment().month() + 1,
                monthB: moment().month() + 1
            },
            isLoading: false,
            data: null
        }
    }
};

let barChart = {};
let pieChart = {};
let lineChart = {};
let compareChart = {};


export default (prevState = initialState, {type, payload, meta}) => {
    switch (type) {
        case SET_DASHBOARD_FILTER:
            return {...prevState, ...{filter: payload}};
        case GET_TICKETS_BAR_CHART_DATA:
            barChart = {
                ...prevState.barChart, ...{
                    tickets: {
                        ...prevState.barChart.tickets,
                        isLoading: true
                    }
                }
            };
            return {...prevState, barChart};
        case GET_VALUES_BAR_CHART_DATA:
            barChart = {
                ...prevState.barChart, ...{
                    values: {
                        ...prevState.barChart.values,
                        isLoading: true
                    }
                }
            };
            return {...prevState, barChart};
        case GET_TICKETS_BAR_CHART_DATA_SUCCESS:
            barChart = {
                ...prevState.barChart, ...{
                    tickets: {
                        ...prevState.barChart.tickets,
                        isLoading: false,
                        data: payload.data
                    }
                }
            };
            return {...prevState, barChart};
        case GET_VALUES_BAR_CHART_DATA_SUCCESS:
            barChart = {
                ...prevState.barChart, ...{
                    values: {
                        ...prevState.barChart.values,
                        isLoading: false,
                        data: payload.data
                    }
                }
            };
            return {...prevState, barChart};
        case GET_TICKETS_BAR_CHART_DATA_ERROR:
            barChart = {
                ...prevState.barChart, ...{
                    tickets: {
                        ...prevState.barChart.tickets,
                        isLoading: false
                    }
                }
            };
            return {...prevState, barChart};
        case GET_VALUES_BAR_CHART_DATA_ERROR:
            barChart = {
                ...prevState.barChart, ...{
                    values: {
                        ...prevState.barChart.values,
                        isLoading: false
                    }
                }
            };
            return {...prevState, barChart};
        case SET_PIE_CHART_FILTER:
            pieChart = {
                ...prevState.pieChart,
                ...{filter: payload.type}
            };
            return {...prevState, pieChart};
        case GET_PIE_CHART_DATA:
            pieChart = {
                ...prevState.pieChart,
                ...{isLoading: true}
            };
            return {...prevState, pieChart};
        case GET_PIE_CHART_DATA_SUCCESS:
            pieChart = {
                ...prevState.pieChart,
                ...{
                    isLoading: false,
                    data: payload.data.series
                }
            };
            return {...prevState, pieChart};
        case GET_PIE_CHART_DATA_ERROR:
            pieChart = {
                ...prevState.pieChart,
                ...{isLoading: false}
            };
            return {...prevState, pieChart};
        case GET_LINE_CHART_DATA:
            lineChart = {
                ...prevState.lineChart,
                ...{isLoading: true}
            };
            return {...prevState, lineChart};
        case GET_LINE_CHART_DATA_SUCCESS:
            lineChart = {
                ...prevState.lineChart,
                ...{
                    isLoading: false,
                    data: payload.data.series
                }
            };
            return {...prevState, lineChart};
        case GET_LINE_CHART_DATA_ERROR:
            lineChart = {
                ...prevState.lineChart,
                ...{isLoading: false}
            };
            return {...prevState, lineChart};
        case SET_COMPARE_BAR_CHART_FILTER:
            compareChart = {
                ...prevState.compareChart,
                [meta.type]: {
                    ...prevState.compareChart[meta.type],
                    filters: {
                        ...prevState.compareChart[meta.type].filters,
                        ...payload
                    }
                }
            };
            return {...prevState, compareChart};
        case GET_COMPARE_BAR_CHART_DATA:
            compareChart = {
                ...prevState.compareChart,
                [meta.type]: {
                    ...prevState.compareChart[meta.type],
                    isLoading: true
                }
            };
            return {...prevState, compareChart};
        case GET_COMPARE_BAR_CHART_DATA_SUCCESS:
            compareChart = {
                ...prevState.compareChart,
                [meta.type]: {
                    ...prevState.compareChart[meta.type],
                    data: payload.data
                }
            };
            return {...prevState, compareChart};
        case GET_COMPARE_BAR_CHART_DATA_ERROR:
            compareChart = {
                ...prevState.compareChart,
                [meta.type]: {
                    ...prevState.compareChart[meta.type],
                    isLoading: false
                }
            };
            return {...prevState, compareChart};
        default:
            return prevState
    }
}
