import React from 'react';
import PropTypes from 'prop-types';
import {DialogTitle} from '@material-ui/core';
import {IconedButton} from './buttons';

const KDialogTitle = (props) => {
    return (
        <DialogTitle id={props.id} style={{
            backgroundColor: '#eee',
            padding: '2px',
        }}>
                                  <span style={{
                                      minHeight: '40px',
                                      verticalAlign: 'middle',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'flex-start'
                                  }}>
                                      <span style={{
                                          lineHeight: '25px',
                                          paddingLeft: '10px',
                                          overflowWrap: 'anywhere',
                                          alignSelf: 'center'
                                      }}>{props.title}</span>
                                      <IconedButton
                                          buttonType="closeButton"
                                          style={{lineHeight: '25px', float: 'right'}}
                                          handleClick={props.handleClick}/>
                                  </span>
        </DialogTitle>
    )
};

KDialogTitle.propTypes = {
    handleClick: PropTypes.func.isRequired,
    id: PropTypes.string,
};

KDialogTitle.defaultProps = {
    id: "modal-dialog"
};

export default (KDialogTitle);
