import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import {Link} from 'react-router-dom';

const renderCells = (colSpan, leftClassName, rightClassName, leftText, rightText) => (
    <React.Fragment>
        {colSpan &&
        <td className={leftClassName} colSpan="2">
            {leftText}
        </td>}
        {!colSpan &&
        <td className={leftClassName}>
            {leftText}
        </td>}
        {!colSpan &&
        <td className={rightClassName}>
            {rightText}
        </td>}
    </React.Fragment>
);

const CardElement = (props) => {
    const {
        classes,
        className,
        hasBulkActions,
        id,
        selected,
        data,
        primaryColSpan,
        secondaryColSpan,
        tertiaryColSpan,
        primaryLeftText,
        primaryRightText,
        secondaryLeftText,
        secondaryRightText,
        tertiaryLeftText,
        tertiaryRightText,
        linkQueryString,
        toolbar,
    } = props;

    const handleToggle = () => {
        props.onToggleItem(props.id);
    };

    const handleClick = (e) => {
        if (props.executeFunction != null) {
            props.executeFunction(props);
            e.preventDefault();
        } else if (!props.executeFunction && !props.linkQueryString) {
            e.preventDefault();
        }
    };

    let qs = '';
    if (linkQueryString) {
        qs = linkQueryString(data[id]);
    }

    return (

        <Card className={className}>
            <div style={{padding: 0, height: '32px'}}>
                {hasBulkActions && (
                    <Checkbox
                        color="primary"
                        // className={`select-item ${classes.checkbox}`}
                        style={{width: '32px', height: '32px'}}
                        checked={selected}
                        onClick={handleToggle}
                        id={id}
                    />
                )}
                {!hasBulkActions && toolbar && (
                    <span style={{lineHeight: '32px', width: '200px'}}>&nbsp;</span>
                )}

                {toolbar && React.cloneElement(toolbar, {...props})}
            </div>
            <Link to={`${qs}`} className={classes.link} onClick={handleClick}>
                <table>
                    <tbody>
                    <tr>
                        {renderCells(
                            primaryColSpan,
                            classes.primaryLeft,
                            classes.primaryRight,
                            primaryLeftText && (primaryLeftText(data[id], id)),
                            primaryRightText && (primaryRightText(data[id], id))
                        )}
                    </tr>
                    <tr>
                        {renderCells(
                            secondaryColSpan,
                            classes.secondaryLeft,
                            classes.secondaryRight,
                            secondaryLeftText && (secondaryLeftText(data[id], id)),
                            secondaryRightText && (secondaryRightText(data[id], id))
                        )}
                    </tr>
                    <tr>
                        {renderCells(
                            tertiaryColSpan,
                            classes.tertiaryLeft,
                            classes.tertiaryRight,
                            tertiaryLeftText && (tertiaryLeftText(data[id], id)),
                            tertiaryRightText && (tertiaryRightText(data[id], id))
                        )}
                    </tr>
                    </tbody>
                </table>
            </Link>
        </Card>
    );
};

CardElement.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    hasBulkActions: PropTypes.bool.isRequired,
    id: PropTypes.any,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    onUnselectItems: PropTypes.func,
    selected: PropTypes.bool,

    data: PropTypes.object,
    ids: PropTypes.array,
    linkQueryString: PropTypes.func,
    elementToolbar: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),

    toolbar: PropTypes.object,
    primaryColSpan: PropTypes.bool,
    secondaryColSpan: PropTypes.bool,
    tertiaryColSpan: PropTypes.bool,
    primaryLeftText: PropTypes.func,
    primaryRightText: PropTypes.func,
    secondaryLeftText: PropTypes.func,
    secondaryRightText: PropTypes.func,
    tertiaryLeftText: PropTypes.func,
    tertiaryRightText: PropTypes.func,
};

CardElement.defaultProps = {
    hasBulkActions: false,
    selected: false,
    primaryColSpan: false,
    secondaryColSpan: false,
    tertiaryColSpan: false,
    linkQueryString: null,
    executeFunction: null,
    toolbar: null,
};

export default CardElement;
