/* eslint-disable */
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY
} from 'react-admin';
import {stringify} from 'query-string';

export const CUSTOM_GET = 'CUSTOM_GET';
export const customGet = 'customGet';
export const CUSTOM_POST = 'CUSTOM_POST';
export const customPost = 'customPost';

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        let url = '';
        const options = {};
        switch (type) {
            case GET_MANY_REFERENCE:
            case GET_LIST:
                const {
                    pagination: {page, perPage},
                    sort: {field, order},
                } = params;
                const getListQuery = {
                    'pageOffset': (page - 1) * perPage,
                    'pageLimit': perPage,
                    'sort': field,
                    'order': order
                };
                Object.keys(params.filter).forEach(key => {
                    if (params.filter[key]) {
                        getListQuery[(key)] = params.filter[key];
                    }
                });
                if (type === GET_MANY_REFERENCE) {
                    getListQuery[(params.target)] = params.id;
                }
                url = `${apiUrl}/${resource}?${stringify(getListQuery)}`;
                break;
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY:
                const getManyQuery = params.ids.toString();
                url = `${apiUrl}/${resource}/ids/${(getManyQuery)}`;
                break;
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PATCH';
                var attrs = {};
                Object.keys(params.data).forEach(key => attrs[key] = params.data[key]);
                delete attrs['id'];
                const updateParams = {data: {type: resource, id: params.id, attributes: attrs}};
                options.body = JSON.stringify(updateParams);
                break;
            /*             case UPDATE_MANY:
                            const updateManyquery = { 'filter[ids]': params.ids.toString() };
                            url = `${apiUrl}/${resource}?${stringify(updateManyquery)}`;
                            options.method = 'PATCH';
                            options.body = JSON.stringify(params.data);
                            break; */
            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                const createParams = {data: {type: resource, attributes: params.data}};
                options.body = JSON.stringify(createParams);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            /*             case DELETE_MANY:
                            const deleteManyquery = { 'filter[ids]': params.ids.toString() };
                            url = `${apiUrl}/${resource}?${stringify(deleteManyquery)}`;
                            options.method = 'DELETE';
                            break; */
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {

        const {json} = response;
        switch (type) {
            case GET_MANY_REFERENCE:
            case GET_LIST:
                var jsonData = json.data.map(dic => {
                    return Object.assign({id: dic.id}, dic);
                });
                if (json.total === undefined) {
                    throw new Error(
                        'The Record-Count or Record-Total is missing in the meta of the HTTP Response.' +
                        ' The JSONAPI REST client expects responses for lists of resources to contain this meta with' +
                        ' the total number of results to build the pagination.'
                    );
                }
                return {data: jsonData, total: json.total};
            case GET_MANY:
                jsonData = json.data.map(obj => {
                    return Object.assign({id: obj.id}, obj);
                });
                return {data: jsonData};
            case UPDATE:
            case CREATE:
                return {data: Object.assign({id: json.data.id}, json.data.attributes)};
            case DELETE:
                return {data: {}};
            case GET_ONE:
                var included = {};
                return {data: Object.assign({id: json.data[0].id}, json.data[0], included)};
            default:
                return {data: json.data};
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {

        let methodUrl = "";
        let methdOptions = "";
        // Until the services supports the multiple update
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id => {
                    const updateParams = {data: {type: resource, id: id, attributes: params.data}};
                    return httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PATCH',
                        body: JSON.stringify(updateParams)
                    })
                })
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }

        //  Until the services supports the multiple delete
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }

        //  custom get list, params will not be modified
        if (type === CUSTOM_GET) {
            methodUrl = `${apiUrl}/${resource}?${stringify(params)}`;
            return httpClient(methodUrl, {})
                .then(response => (response.json));
        }

        if (type === customGet) {
            methodUrl = `${apiUrl}/${resource}?${stringify(params)}`;
            return httpClient(methodUrl, {})
                .then(response => ({data: response.json}));
        }

        //  custom get list, params will not be modified
        if (type === CUSTOM_POST) {
            methdOptions = {
                method: 'POST',
                body: JSON.stringify(params)
            };

            methodUrl = `${apiUrl}/${resource}`;
            return httpClient(methodUrl, methdOptions)
                .then(response => (response.json));
        }
        if (type === customPost) {
            methdOptions = {
                method: 'POST',
                body: JSON.stringify(params)
            };

            methodUrl = `${apiUrl}/${resource}`;
            return httpClient(methodUrl, methdOptions)
                .then(response => ({data: response.json}));
        }

        const {url, options} = convertRESTRequestToHTTP(type, resource, params);
        return httpClient(url, options)
            .then(response => convertHTTPResponseToREST(response, type, resource, params));
    };
};

