import {baseApiUrl} from './App';
import {get} from 'lodash';

const jwtDecode = require('jwt-decode');

export const SESSION_TOKEN = 'token';
export const SESSION_ACCOUNT_ID = 'sub';
export const SESSION_ACCOUNT_USERNAME = 'username';
export const SESSION_ACCOUNT_NAME = 'user';
export const LANGUAGE = 'language';

export function setRequestSessionHeaders(headers) {
    headers.set('Authorization', `Bearer ${localStorage.getItem(SESSION_TOKEN)}`);
}

export default {
    login: params => {
        const {username, password} = params;
        const authApiUrl = baseApiUrl.replace("tabroker-admin", "auth");
        const request = new Request(`${authApiUrl}/login`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'})
        });
        return fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json()
        }).then(json => {
            const token = get(json, SESSION_TOKEN);
            const accountName = get(json, SESSION_ACCOUNT_NAME);
            const jwt = jwtDecode(token);
            localStorage.setItem(SESSION_TOKEN, token);
            localStorage.setItem(SESSION_ACCOUNT_ID, get(jwt, SESSION_ACCOUNT_ID));
            localStorage.setItem(SESSION_ACCOUNT_USERNAME, username);
            localStorage.setItem(SESSION_ACCOUNT_NAME, accountName);
            localStorage.setItem(LANGUAGE, 'it-IT');
            return Promise.resolve();
        });
    },
    logout: () => {
        localStorage.removeItem(SESSION_TOKEN);
        localStorage.removeItem(SESSION_ACCOUNT_ID);
        localStorage.removeItem(SESSION_ACCOUNT_USERNAME);
        localStorage.removeItem(SESSION_ACCOUNT_NAME);
        localStorage.removeItem(LANGUAGE);
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem(SESSION_TOKEN)
            ? Promise.resolve()
            : Promise.reject({redirectTo: '/login'});
    },
    checkError: (error) => {
        if (error.status === 401 || error.status === 403) {
            return Promise.reject({redirectTo: '/login'});
        }
        return Promise.resolve()
    },
    getPermissions: () => Promise.reject('Unknown method')
};
