import React, {useEffect, useState} from 'react';
import {
    Datagrid,
    fetchEnd,
    fetchStart,
    Filter,
    List,
    SelectField,
    SelectInput,
    TextField,
    useDataProvider,
    useTranslate
} from 'react-admin';
import {parse} from 'query-string';
import {ChipArrayField, DialogTripCalendar} from "../components";
import {LinkButton} from "../components/buttons";
import {CardActions, makeStyles, Paper} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import TrueIcon from '@material-ui/icons/Check';
import PickUpIcon from '@material-ui/icons/CallMade';
import DropOffIcon from '@material-ui/icons/CallReceived';
import NoActionIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
    headerCell: {
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    paper: {
        maxHeight: 'calc(60vh - 100px)',
        overflowY: 'auto',
        marginTop: 4
    }
});

const directionChoices = [
    {id: 1, name: 'tripType.outbound'},
    {id: 2, name: 'tripType.inbound'}
];

const onlyTrue = [
    {id: true, name: <TrueIcon/>},
    {id: false, name: ''}
];

const pickUpChoice = [
    {id: true, name: <PickUpIcon/>},
    {id: false, name: <NoActionIcon/>}
];
const dropOffChoice = [
    {id: true, name: <DropOffIcon/>},
    {id: false, name: <NoActionIcon/>}
];

export const UiServiceTrips = (props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const [serviceId, setServiceId] = useState('');
    const [shortDescription, setShortDescription] = useState(null);

    useEffect(() => {
        function getServiceData(id) {
            dispatch(fetchStart());
            dataProvider.getOne('services', {id: id})
                .then(response => {
                    setShortDescription(response.data.shortDescription);
                })
                .finally(() => {
                    dispatch(fetchEnd());
                })
        }

        if (props.location &&
            props.location.search) {
            const par1 = parse(props.location.search.substring(1));
            const par2 = JSON.parse(par1['filter']);
            setServiceId(par2['serviceId']);
            getServiceData(par2['serviceId']);
        }
    }, [props.location, dataProvider, dispatch]);

    function getTitle() {
        let title = translate('serviceTrips.title');
        if (shortDescription) {
            title = `${shortDescription} ${translate('serviceTrips.title')}`;
        }
        return title;
    }

    const Filters = (filterProps) => (
        <Filter {...filterProps} variant="outlined">
            <SelectInput
                source={'direction'}
                choices={directionChoices}
                alwaysOn
                clearAlwaysVisible={false}
                resettable
                emptyText={'allSelectInput.emptyText'}
                options={{
                    SelectProps: {displayEmpty: true},
                    InputLabelProps: {shrink: true}
                }}
            />
        </Filter>
    );

    const ListActions = ({resource, filters, displayedFilters, filterValues, showFilter}) => {
        return (
            <CardActions>
                {filters && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
                <LinkButton to={'/services'} label={translate('serviceTrips.servicesButton')}/>
            </CardActions>
        );
    };

    const StopsPanel = ({record}) => {
        const tripStops = record['serviceTripStops'];
        if (tripStops && tripStops.length > 0) {
            const data = {};
            const ids = [];
            tripStops.forEach(tripStop => {
                const id = `${tripStop.serviceTripId}-${tripStop.sequence}`;
                data[id] = tripStop;
                ids.push(id)
            });
            return <Paper className={classes.paper}>
                <Datagrid
                    classes={{headerCell: classes.headerCell}}
                    data={data}
                    ids={ids}
                    currentSort={{
                        field: 'sequence',
                        order: 'ASC'
                    }}
                >
                    <TextField source={'sequence'} label={translate('serviceTrips.tripStops.sequence')}
                               sortable={false}/>
                    <TextField source={'segment'} label={translate('serviceTrips.tripStops.segment')} sortable={false}/>
                    <TextField source={'stopId'} label={translate('serviceTrips.tripStops.stopId')} sortable={false}/>
                    <TextField source={'stopDescription'} label={translate('serviceTrips.tripStops.stop')}
                               sortable={false}/>
                    <SelectField source={'connection'} choices={onlyTrue} translateChoice={false}
                                 label={translate('serviceTrips.tripStops.connection')}
                                 sortable={false}/>
                    <TextField source={'departureTime'} label={translate('serviceTrips.tripStops.departureTime')}
                               sortable={false}/>
                    <TextField source={'arrivalTime'} label={translate('serviceTrips.tripStops.arrivalTime')}
                               sortable={false}/>
                    <TextField source={'distance'} label={translate('serviceTrips.tripStops.distance')}
                               sortable={false}/>
                    <SelectField source={'stopOnRequest'} label={translate('serviceTrips.tripStops.stopOnRequest')}
                                 sortable={false} translateChoice={false} choices={onlyTrue}/>
                    <SelectField choices={dropOffChoice} translateChoice={false} source={'dropOff'}
                                 label={translate('serviceTrips.tripStops.dropOff')} sortable={false}/>
                    <SelectField choices={pickUpChoice} translateChoice={false} source={'pickUp'}
                                 label={translate('serviceTrips.tripStops.pickUp')} sortable={false}/>
                    <TextField source={'chargeableOriginStopId'}
                               label={translate('serviceTrips.tripStops.chargeableOriginStop')} sortable={false}/>
                    <TextField source={'chargeableDestinationStopId'}
                               label={translate('serviceTrips.tripStops.chargeableDestinationStop')} sortable={false}/>
                    <ChipArrayField source={'tripIds'} label={translate('serviceTrips.tripStops.tripIds')}
                                    sortable={false}/>
                </Datagrid>
            </Paper>
        } else {
            return <div/>
        }
    };

    if (!serviceId) {
        return <div/>
    }
    return (
        <List
            title={getTitle()}
            bulkActionButtons={false}
            filter={{serviceId: serviceId}}
            filters={<Filters/>}
            actions={<ListActions/>}
            filterDefaultValues={{direction: 1}}
            {...props}
        >
            <Datagrid expand={<StopsPanel classes={classes}/>} hover={false}>
                <TextField source={'id'}/>
                <SelectField
                    choices={directionChoices}
                    source={'direction'}
                />
                <TextField source={'origin'}/>
                <TextField source={'destination'}/>
                <TextField source={'departureTime'}/>
                <TextField source={'arrivalTime'}/>
                <DialogTripCalendar/>
            </Datagrid>
        </List>
    );
};
