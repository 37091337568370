import React, {Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Drawer, makeStyles, useMediaQuery} from '@material-ui/core';
import lodashGet from 'lodash/get';
import {setSidebarVisibility} from 'react-admin';

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 55;

const useStyles = makeStyles(
    theme => ({
        drawerPaper: {
            position: 'relative',
            height: 'auto',
            overflowX: 'hidden',
            width: props =>
                props.open
                    ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
                    : lodashGet(
                    theme,
                    'sidebar.closedWidth',
                    CLOSED_DRAWER_WIDTH
                    ),
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: 'transparent',
            marginTop: '0.5em',
            borderRight: 'none',
            [theme.breakpoints.only('xs')]: {
                marginTop: 0,
                height: '100vh',
                position: 'inherit',
                backgroundColor: theme.palette.background.default,
            },
            [theme.breakpoints.up('md')]: {
                border: 'none',
                marginTop: '1.5em',
            },
        },
    }),
    {name: 'RaSidebar'}
);

const TaBrokerSidebar = ({
                             children,
                             closedSize,
                             size,
                             classes: classesOverride,
                             ...rest
                         }) => {
    const dispatch = useDispatch();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector(state => state.locale); // force redraw on locale change
    const handleClose = () => dispatch(setSidebarVisibility(false));
    const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
    const classes = useStyles({classes: classesOverride, open});

    if (isXSmall) {
        return (
            <Drawer
                variant="temporary"
                open={open}
                PaperProps={{
                    className: classes.drawerPaper,
                }}
                onClose={toggleSidebar}
                {...rest}
            >
                {cloneElement(Children.only(children), {
                    onMenuClick: handleClose,
                })}
            </Drawer>
        );
    }
    return isSmall ? (
        <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
                className: classes.drawerPaper,
            }}
            onClose={toggleSidebar}
            {...rest}
        >
            {cloneElement(Children.only(children), {
                dense: true,
                onMenuClick: handleClose,
            })}
        </Drawer>
    ) : (
        <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
                className: classes.drawerPaper,
            }}
            onClose={toggleSidebar}
            {...rest}
        >
            {cloneElement(Children.only(children), {dense: true})}
        </Drawer>
    );
};

TaBrokerSidebar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default TaBrokerSidebar;
