import React from 'react';
import PropTypes from 'prop-types';
import {DialogTitle, Step, StepButton, Stepper} from '@material-ui/core';
import {IconedButton} from './buttons';

const KStepDialogTitle = (props) => {
    return (
        <DialogTitle id="add-new-route" style={{backgroundColor: '#eee', padding: '2px'}}>
                  <span style={{lineHeight: '40px', verticalAlign: 'middle'}}>
                      <span style={{lineHeight: '20px', paddingLeft: '10px'}}>{props.title}</span>
                      <IconedButton
                          buttonType="closeButton"
                          style={{lineHeight: '20px', float: 'right'}}
                          handleClick={props.handleClick}/>
                  </span>
            <Stepper nonLinear activeStep={props.step} style={{backgroundColor: '#eee'}}>
                {props.steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepButton
                                // onClick={this.handleStep(index)}
                                // completed={this.state.completed[index]}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        </DialogTitle>
    )
};

KStepDialogTitle.propTypes = {
    handleClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    step: PropTypes.number.isRequired,
    steps: PropTypes.object.isRequired,
};

KStepDialogTitle.defaultProps = {};

export default KStepDialogTitle;
