module.exports = {
    appBar: {
        userText: 'Benvenuto/a, %{accountName}'
    },
    exportButton: {
        emptyTitle: 'Il file generato è vuoto',
        error: 'Errore: non è possibile scaricare il file',
        generate: 'Genera file',
        loading: 'Loading...',
        download: 'Download',
        preparing: 'Preparando...'
    },
    bookings: {
        passengers: {
            firstName: 'Nome',
            lastName: 'Cognome',
            email: 'Email',
            phone: 'Telefono',
            passengerCategory: 'Categoria passeggero',
            outboundSeat: 'N° posto andata',
            inboundSeat: 'N° posto ritorno',
            itemCode: 'Codice articolo',
            price: 'Prezzo'
        },
        cancelBooking: {
            buttonLabel: 'Cancella prenotazione |||| Cancella prenotazioni',
            title: 'Cancella prenotazione |||| Cancella prenotazioni',
            content: 'Sei sicuro di voler cancellare la prenotazione selezionata? |||| Sei sicuro di voler cancellare le prenotazioni selezionate?',
            response: 'La prenotazione selezionata e\' stata cancellata |||| Le prenotazioni selezionate sono state cancellate'
        }
    },
    serviceTrips: {
        title: 'Corse',
        servicesButton: 'Servizi',
        tripStops: {
            sequence: 'Sequenza',
            segment: 'Segmento',
            stopId: 'Codice fermata',
            stop: 'Descrizione fermata',
            connection: 'Coincidenza',
            departureTime: 'Ora di partenza',
            arrivalTime: 'Ora di arrivo',
            distance: 'Distanza',
            stopOnRequest: 'Fermata su richiesta',
            dropOff: 'Discesa',
            pickUp: 'Salita',
            chargeableOriginStop: 'Fermata di origine tariffabile',
            chargeableDestinationStop: 'Fermata di destinazione tariffabile',
            tripIds: 'Corse P.E.'
        }
    },
    productOptions: {
        title: 'Opzioni',
        productsButton: 'Prodotti'
    },
    dashboard: {
        filter: {
            agency: {
                name: 'Agenzia'
            },
            period: {
                name: 'Periodo',
                day: 'Ultime 24 ore',
                week: 'Ultimi 7 giorni',
                month: 'Ultimi 30 giorni'
            }
        },
        barChart: {
            yAxisTitles: {
                tickets: 'Biglietti',
                prices: 'Ricavi lordi',
            },
            confirmed: 'Confermati',
            cancelled: 'Cancellati',
            earned: 'Confermati',
            refunded: 'Cancellati'
        },
        pieChart: {
            filter: {
                services: 'Servizi',
                products: 'Prodotti'
            },
            series: {
                services: 'Servizi venduti',
                products: 'Prodotti venduti'
            }
        },
        lineChart: {
            title: 'Biglietti confermati',
            weekdays: 'Domenica,Lunedì,Martedì,Mercoledì,Giovedì,Venerdì,Sabato',
            months: 'Gennaio,Febbraio,Marzo,Aprile,Maggio,Giugno,Luglio,Agosto,Settembre,Ottobre,Novembre,Dicembre',
            shortMonths: 'Gen,Feb,Mar,Apr,Mag,Giu,Lug,Ago,Set,Ott,Nov,Dic'

        },
        compareChart: {
            yAxisTitles: {
                tickets: 'Confronta biglietti confermati',
                values: 'Confronta ricavi lordi confermati'
            }
        }
    },
    dialogCalendar: {
        recordTitle: 'Calendario corsa %{id}',
        noRecordTitle: 'Calendario corsa',
        description: 'Partenza da \'%{origin}\' alle ore %{departureTime} - Arrivo a \'%{destination}\' alle ore %{arrivalTime}',
        buttonLabel: 'Calendario'
    },
    dialogExportReport: {
        defaultTitle: 'Report',
        generate: {
            default: 'Genera',
            agency: 'Genera report agenzia',
            agent: 'Genera report agente'
        },
        input: {
            month: 'Mese',
            year: 'Anno',
            agency: 'Agenzia',
            agent: 'Agente',
            datesRange: {
                date: "Del giorno",
                fromDate: 'Da data',
                toDate: 'A data',
                checkbox: 'Partenze da data a data'
            }
        }
    },
    reports: {
        noReports: 'Nessun report disponibile',
        files: {
            gygBookings: 'Prenotazioni GetYourGuide',
            gygDepartures: 'Viaggiato GetYourGuide',
            goeuroBookings: 'Prenotazioni Omio',
            daaabBookings: 'Prenotazioni DaAaB',
            departures: 'Partenze',
            bookings: 'Prenotazioni'
        },
        datesRange: 'dal %{fromDate} al %{toDate}',
        oneDate: '%{fromDate}'
    },
    passengerChoice: {
        adult: 'Adulto',
        child: 'Bambino',
        infant: 'Neonato'
    },
    tripType: {
        outbound: 'Andata',
        inbound: 'Ritorno',
        oneWayPax: 'Sola andata',
        returnPax: 'Andata e ritorno'
    },
    statusChoice: {
        confirmed: 'Confermate',
        cancelled: 'Cancellate'
    },
    allSelectInput: {
        emptyText: 'Tutti'
    },
    languageMenu: {
        en: 'Inglese',
        it: 'Italiano'
    },
    fontSizeSelector: {
        fontSize: 'Dimensione caratteri',
        toggleButtons: {
            small: 'Piccolo %{value}px',
            medium: 'Medio %{value}px',
            large: 'Grande %{value}px'
        }
    },
    resources: {
        bookings: {
            name: "Prenotazioni",
            fields: {
                id: 'Numero prenotazione',
                bookingDate: 'Data prenotazione',
                seller: 'Agenzia',
                outboundDepartureDateTime: 'Data partenza (Andata)',
                outboundArrivalDateTime: 'Data arrivo (Andata)',
                inboundDepartureDateTime: 'Data partenza (Ritorno)',
                inboundArrivalDateTime: 'Data arrivo (Ritorno)',
                originStopId: 'Origine',
                destinationStopId: 'Destinazione',
                serviceId: 'Servizio',
                productId: 'Prodotto',
                totalPrice: 'Prezzo totale',
                fromDate: 'Da data partenza',
                toDate: 'A data partenza',
                agencyId: 'Agenzia',
                extBookingId: 'Prenotazione esterna',
                status: 'Stato'
            }
        },
        services: {
            name: 'Servizi',
            fields: {
                q: 'Cerca',
                id: 'Id servizio',
                shortDescription: 'Descrizione',
                description: 'Descrizione completa',
                reservationRequired: 'Prenotazione posto richiesta'
            }
        },
        'service-trips': {
            name: 'Corse',
            fields: {
                id: 'Id',
                direction: 'Direzione',
                origin: 'Fermata di partenza',
                destination: 'Fermata di arrivo',
                departureTime: 'Ora di partenza',
                arrivalTime: 'Ora di arrivo'
            }
        },
        products: {
            name: 'Prodotti',
            fields: {
                q: 'Cerca',
                id: 'Id prodotto',
                shortDescription: 'Descrizione',
                description: 'Descrizione completa',
                shelfProduct: 'Biglietto aperto'
            }
        },
        'product-options': {
            name: 'Opzioni prodotto',
            fields: {
                tripType: 'Tipo di viaggio',
                id: 'Id',
                shortDescription: 'Descrizione',
                description: 'Descrizione completa',
                tripTypeId: 'Tipo di viaggio',
                serviceId: 'Servizio',
                direction: 'Direzione',
                originStopId: 'Origine',
                destinationStopId: 'Destinazione'
            }
        },
        reports: {
            name: 'Reports'
        }
    },
    ra: {
        action: {
            add_filter: 'Aggiungi un filtro',
            add: 'Aggiungi',
            back: 'Indietro',
            bulk_actions: '%{smart_count} selezionato |||| %{smart_count} selezionati',
            ok: 'Ok',
            cancel: 'Annulla',
            clear_input_value: 'Svuota il modulo',
            clear: 'Svuota',
            clone: 'Duplica',
            confirm: 'Conferma',
            create: 'Crea',
            delete: 'Cancella',
            edit: 'Modifica',
            export: 'Esporta',
            list: 'Elenco',
            refresh: 'Aggiorna',
            remove_filter: 'Rimuovi questo filtro',
            remove: 'Remove',
            save: 'Salva',
            search: 'Ricerca',
            show: 'Mostra',
            sort: 'Ordina',
            undo: 'Annulla',
            expand: 'Espandi',
            close: 'Chiudi'
        },
        boolean: {
            true: 'Si',
            false: 'No',
            null: ''
        },
        page: {
            create: 'Aggiungi %{name}',
            dashboard: 'Dashboard',
            edit: '%{name} %{id}',
            error: 'Qualcosa è andato storto',
            list: 'Lista %{name}',
            loading: 'Caricamento in corso',
            delete: 'Elimina %{name} %{id}',
            not_found: 'Non trovato',
            show: '%{name} %{id}'
        },
        input: {
            file: {
                upload_several:
                    'Trascina i files da caricare, oppure clicca per selezionare.',
                upload_single: 'Trascina il file da caricare, oppure clicca per selezionarlo.'
            },
            image: {
                upload_several:
                    'Trascina le immagini da caricare, oppure clicca per selezionarle.',
                upload_single:
                    'Trascina l\'immagine da caricare, oppure clicca per selezionarla.'
            },
            references: {
                all_missing: 'Impossibile trovare i riferimenti associati.',
                many_missing:
                    'Almeno uno dei riferimenti associati non sembra più disponibile.',
                single_missing:
                    'Il riferimento associato non sembra più disponibile.'
            },
            password: {
                toggle_visible: 'Nascondi password',
                toggle_hidden: 'Mostra password',
            },
        },
        message: {
            about: 'Informazioni',
            are_you_sure: 'Sei sicuro ?',
            bulk_delete_content:
                'Sei sicuro di voler cancellare questo %{name}? |||| Sei sicuro di voler eliminare questi %{smart_count}?',
            bulk_delete_title:
                'Delete %{name} |||| Delete %{smart_count} %{name} items',
            delete_content: 'Are you sure you want to delete this item?',
            delete_title: 'Cancella %{name} #%{id}',
            details: 'Dettagli',
            error:
                'Un errore locale è occorso e la tua richiesta non è stata completata.',
            invalid_form: 'Il modulo non è valido. Si prega di verificare la presenza di errori.',
            loading: 'La pagina si sta caricando, solo un momento per favore',
            no: 'No',
            not_found:
                'Hai inserito un URL errato, oppure hai cliccato un link errato',
            yes: 'Si'
        },
        navigation: {
            no_results: 'Nessun risultato trovato',
            no_more_results:
                'La pagina numero %{page} è fuori dell\'intervallo. Prova la pagina precedente.',
            page_out_of_boundaries: 'Il numero di pagina %{page} è fuori dei limiti',
            page_out_from_end: 'Fine della paginazione',
            page_out_from_begin: 'Il numero di pagina deve essere maggiore di 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} di %{total}',
            page_rows_per_page: 'Righe per pagina',
            next: 'Successivo',
            prev: 'Precedente'
        },
        auth: {
            auth_check_error: 'Esegui login per continuare',
            user_menu: 'Profile',
            username: 'Nome utente',
            password: 'Password',
            sign_in: 'Login',
            sign_in_error: 'Autenticazione fallita, riprovare.',
            logout: 'Logout'
        },
        notification: {
            updated: 'Record aggiornato |||| %{smart_count} records aggiornati',
            created: 'Record creato',
            deleted: 'Record eliminato |||| %{smart_count} records eliminati',
            bad_item: 'Record errato',
            item_doesnt_exist: 'Record inesistente',
            http_error: 'Errore di comunicazione con il server dati',
            data_provider_error:
                'Errore del data provider. Controlla la console per i dettagli.',
            i18n_error:
                'Impossibile caricare lingua selezionata',
            canceled: 'Azione annullata',
            logged_out: 'La tua sesseione è scaduta, riconnettiti.',
        },
        validation: {
            required: 'Campo obbligatorio',
            minLength: 'Deve essere lungo %{min} caratteri almeno',
            maxLength: 'Deve essere lungo %{max} caratteri al massimo',
            minValue: 'Deve essere almeno %{min}',
            maxValue: 'Deve essere al massimo %{max}',
            number: 'Deve essere un numero',
            email: 'Deve essere un valido indirizzo email',
            oneOf: 'Deve essere uno di: %{options}',
            regex: 'Deve rispettare il formato (espressione regolare): %{pattern}',
            invalid_date: 'Data non valida'
        }

    }
};
