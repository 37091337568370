import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {addField, Labeled, LinearProgress} from 'react-admin';
import CustomReferenceInputController from "./CustomReferenceInputController";
import TextField from '@material-ui/core/TextField';

const sanitizeRestProps = ({
                               allowEmpty,
                               basePath,
                               choices,
                               className,
                               component,
                               crudGetMatching,
                               crudGetOne,
                               defaultValue,
                               endpoint,
                               fetchClient,
                               fetchAction,
                               filterToQuery,
                               formClassName,
                               initializeForm,
                               input,
                               isRequired,
                               label,
                               locale,
                               meta,
                               onChange,
                               optionValue,
                               optionText,
                               perPage,
                               record,
                               reference,
                               referenceSource,
                               resource,
                               setFilter,
                               setPagination,
                               setSort,
                               sort,
                               source,
                               textAlign,
                               translate,
                               translateChoice,
                               validation,
                               ...rest
                           }) => rest;

export const CustomReferenceInputView = ({
                                             allowEmpty,
                                             basePath,
                                             children,
                                             choices,
                                             classes,
                                             className,
                                             error,
                                             input,
                                             isRequired,
                                             isLoading,
                                             label,
                                             meta,
                                             onChange,
                                             resource,
                                             setFilter,
                                             setPagination,
                                             setSort,
                                             source,
                                             translate,
                                             warning,
                                             ...rest
                                         }) => {
    if (isLoading) {
        return (
            <Labeled
                label={label}
                source={source}
                resource={resource}
                className={className}
                isRequired={isRequired}
            >
                <LinearProgress/>
            </Labeled>
        );
    }

    if (error) {
        return <TextField error disabled label={label} value={error} margin="normal"/>;
    }

    return React.cloneElement(children, {
        allowEmpty,
        classes,
        className,
        input,
        isRequired,
        label,
        meta: {
            ...meta,
            helperText: warning || false,
        },
        source,
        choices,
        onChange,
        setFilter,
        ...sanitizeRestProps(rest),
    });
};

CustomReferenceInputView.propTypes = {
    allowEmpty: PropTypes.bool,
    basePath: PropTypes.string,
    children: PropTypes.element,
    choices: PropTypes.array,
    classes: PropTypes.object,
    className: PropTypes.string,
    error: PropTypes.string,
    input: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    onChange: PropTypes.func,
    resource: PropTypes.string,
    setFilter: PropTypes.func,
    setPagination: PropTypes.func,
    setSort: PropTypes.func,
    source: PropTypes.string,
    warning: PropTypes.string,
};

/**
 * An Input component for choosing a reference record. Useful for foreign keys.
 *
 * This component fetches the possible values in the reference resource
 * (using the fetchClient, and fetchMethod), then delegates rendering
 * to a subcomponent, to which it passes the possible choices
 * as the `choices` attribute.
 *

 * @example
 * <CustomReferenceInput
 *      source="post_id"
 *      reference="posts"
 *      fetchAction={CUSTOM_GET}
 fetchClient={apiClient}
 endpoint="posts"
 *      filterToQuery={searchText => ({ title: searchText, name: myName })}>
 *     <SelectInput optionText="title" />
 * </CustomReferenceInput>
 */
export const CustomReferenceInput = ({children, ...props}) => {
    if (React.Children.count(children) !== 1) {
        throw new Error('<CustomReferenceInput> only accepts a single child');
    }
    return (
        <CustomReferenceInputController {...props}>
            {controllerProps => (
                <CustomReferenceInputView
                    {...props}
                    {...{children, ...controllerProps}}
                />
            )}
        </CustomReferenceInputController>
    );
};

CustomReferenceInput.propTypes = {
    allowEmpty: PropTypes.bool.isRequired,
    basePath: PropTypes.string,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object,
    endpoint: PropTypes.string,
    fetchClient: PropTypes.func,
    fetchAction: PropTypes.string,
    filterToQuery: PropTypes.func.isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object,
    onChange: PropTypes.func,
    record: PropTypes.object,
    reference: PropTypes.string.isRequired,
    resource: PropTypes.string,
    source: PropTypes.string,
};

CustomReferenceInput.defaultProps = {
    allowEmpty: false,
    filterToQuery: searchText => ({q: searchText}),
};

const EnhancedCustomReferenceInput = compose(
    addField
)(CustomReferenceInput);

export default EnhancedCustomReferenceInput;
