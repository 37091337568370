import {all, call, put, takeEvery} from 'redux-saga/effects';
import {FETCH_ERROR} from 'react-admin';

const NO_CONTAINER_ERROR = 'REST response must contain a data key';

const dashboardSaga = restClient => {

    function* fetchTicketsBarChart({meta, payload}) {
        let response;

        try {
            response = yield call(restClient, 'CUSTOM_GET', meta.resource, payload);
            if (!response.data) {
                throw new Error(NO_CONTAINER_ERROR);
            }
            yield put({
                type: 'GET_TICKETS_BAR_CHART_DATA_SUCCESS',
                payload: response,
                requestPayload: payload,
                meta: meta
            });
        } catch (error) {
            yield put({type: 'GET_TICKETS_BAR_CHART_DATA_ERROR', error});
            if (error.status === 401 || error.status === 403) {
                yield put({type: FETCH_ERROR})
            }
        }
    }

    function* fetchValuesBarChart({meta, payload}) {
        let response;

        try {
            response = yield call(restClient, 'CUSTOM_GET', meta.resource, payload);
            if (!response.data) {
                throw new Error(NO_CONTAINER_ERROR);
            }
            yield put({
                type: 'GET_VALUES_BAR_CHART_DATA_SUCCESS',
                payload: response,
                requestPayload: payload,
                meta: meta
            });
        } catch (error) {
            yield put({type: 'GET_VALUES_BAR_CHART_DATA_ERROR', error});
            if (error.status === 401 || error.status === 403) {
                yield put({type: FETCH_ERROR})
            }
        }
    }

    function* fetchPieChart({meta, payload}) {
        let response;
        try {
            response = yield call(restClient, 'CUSTOM_GET', meta.resource, payload);
            if (!response.data) {
                throw new Error(NO_CONTAINER_ERROR);
            }
            yield put({
                type: 'GET_PIE_CHART_DATA_SUCCESS',
                payload: response,
                requestPayload: payload,
                meta: meta
            })
        } catch (error) {
            yield put({type: 'GET_PIE_CHART_DATA_ERROR', error});
            if (error.status === 401 || error.status === 403) {
                yield put({type: FETCH_ERROR})
            }
        }
    }

    function* fetchLineChart({meta, payload}) {
        let response;
        try {
            response = yield call(restClient, 'CUSTOM_GET', meta.resource, payload);
            if (!response.data) {
                throw new Error(NO_CONTAINER_ERROR);
            }
            yield put({
                type: 'GET_LINE_CHART_DATA_SUCCESS',
                payload: response,
                requestPayload: payload,
                meta: meta
            })
        } catch (error) {
            yield put({type: 'GET_LINE_CHART_DATA_ERROR', error});
            if (error.status === 401 || error.status === 403) {
                yield put({type: FETCH_ERROR})
            }
        }
    }

    function* fetchCompareBarChart({meta, payload}) {
        let response;
        try {
            response = yield call(restClient, 'CUSTOM_GET', meta.resource, payload);
            if (!response.data) {
                throw new Error(NO_CONTAINER_ERROR);
            }
            yield put({
                type: 'GET_COMPARE_BAR_CHART_DATA_SUCCESS',
                payload: response,
                requestPayload: payload,
                meta: meta
            })
        } catch (error) {
            yield put({type: 'GET_COMPARE_BAR_CHART_DATA_ERROR', meta: meta, error});
            if (error.status === 401 || error.status === 403) {
                yield put({type: FETCH_ERROR})
            }
        }
    }

    return function* watchDashboardSaga() {
        yield all([
            takeEvery('GET_TICKETS_BAR_CHART_DATA', fetchTicketsBarChart),
            takeEvery('GET_VALUES_BAR_CHART_DATA', fetchValuesBarChart),
            takeEvery('GET_PIE_CHART_DATA', fetchPieChart),
            takeEvery('GET_LINE_CHART_DATA', fetchLineChart),
            takeEvery('GET_COMPARE_BAR_CHART_DATA', fetchCompareBarChart)
        ])
    };
};

export default dashboardSaga;
