export const SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER';
export const GET_TICKETS_BAR_CHART_DATA = 'GET_TICKETS_BAR_CHART_DATA';
export const GET_TICKETS_BAR_CHART_DATA_SUCCESS = 'GET_TICKETS_BAR_CHART_DATA_SUCCESS';
export const GET_TICKETS_BAR_CHART_DATA_ERROR = 'GET_TICKETS_BAR_CHART_DATA_ERROR';
export const GET_VALUES_BAR_CHART_DATA = 'GET_VALUES_BAR_CHART_DATA';
export const GET_VALUES_BAR_CHART_DATA_SUCCESS = 'GET_VALUES_BAR_CHART_DATA_SUCCESS';
export const GET_VALUES_BAR_CHART_DATA_ERROR = 'GET_VALUES_BAR_CHART_DATA_ERROR';
export const SET_PIE_CHART_FILTER = 'SET_PIE_CHART_FILTER';
export const GET_PIE_CHART_DATA = 'GET_PIE_CHART_DATA';
export const GET_PIE_CHART_DATA_SUCCESS = 'GET_PIE_CHART_DATA_SUCCESS';
export const GET_PIE_CHART_DATA_ERROR = 'GET_PIE_CHART_DATA_ERROR';
export const GET_LINE_CHART_DATA = 'GET_LINE_CHART_DATA';
export const GET_LINE_CHART_DATA_SUCCESS = 'GET_LINE_CHART_DATA_SUCCESS';
export const GET_LINE_CHART_DATA_ERROR = 'GET_LINE_CHART_DATA_ERROR';
export const GET_COMPARE_BAR_CHART_DATA = 'GET_COMPARE_BAR_CHART_DATA';
export const GET_COMPARE_BAR_CHART_DATA_SUCCESS = 'GET_COMPARE_BAR_CHART_DATA_SUCCESS';
export const GET_COMPARE_BAR_CHART_DATA_ERROR = 'GET_COMPARE_BAR_CHART_DATA_ERROR';
export const SET_COMPARE_BAR_CHART_FILTER = 'SET_COMPARE_BAR_CHART_FILTER';

export const setDashboardFilter = (agency = null, period = 720) => ({
    type: SET_DASHBOARD_FILTER,
    payload: {
        agency: agency,
        period: period
    }
});

export const setCompareBarChartFilter = (type = 'tickets', yearA = 0, yearB = 0, monthA = 0, monthB = 0) => ({
    type: SET_COMPARE_BAR_CHART_FILTER,
    meta: {type: type},
    payload: {
        yearA: yearA,
        yearB: yearB,
        monthA: monthA,
        monthB: monthB,
    }
});

export const getCompareBarChart = (type = 'tickets', yearA = 0, yearB = 0, monthA = 0, monthB = 0) => {
    const resource = `dashboard/compare/${type}`;
    return ({
        type: GET_COMPARE_BAR_CHART_DATA,
        meta: {resource: resource, type: type},
        payload: {
            yearA: yearA,
            yearB: yearB,
            monthA: monthA,
            monthB: monthB
        }
    })
};

export const getTicketsBarChart = (agency = 0, period = 720) => {
    const resource = `dashboard/bar/tickets`;
    return ({
        type: GET_TICKETS_BAR_CHART_DATA,
        meta: {resource: resource},
        payload: {
            agencyId: agency,
            period: period
        }
    });
};

export const getValuesBarChart = (agency = 0, period = 720) => {
    const resource = `dashboard/bar/prices`;
    return ({
        type: GET_VALUES_BAR_CHART_DATA,
        meta: {resource: resource},
        payload: {
            agencyId: agency,
            period: period
        }
    });
};

export const setPieChartFilter = (type = 'services') => ({
    type: SET_PIE_CHART_FILTER,
    payload: {
        type: type
    }
});

export const getPieChart = (agency = 0, period = 720, type = 'services') => {
    const resource = 'dashboard/pie/sold';
    return ({
        type: GET_PIE_CHART_DATA,
        meta: {resource: resource},
        payload: {
            agencyId: agency,
            period: period,
            type: type
        }
    })
};

export const getLineChart = (agency = 0, period = 720) => {
    const groupBy = (period === 24) ? 'hour' : 'day';
    const resource = `dashboard/line/${groupBy}/tickets`;
    return ({
        type: GET_LINE_CHART_DATA,
        meta: {resource: resource},
        payload: {
            agencyId: agency,
            period: period
        }
    })
};
