import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    KeyboardDatePicker as DatePicker,
    KeyboardDateTimePicker as DateTimePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {FieldTitle, useInput, useTranslate} from 'react-admin';
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import {LANGUAGE} from '../../authClient';

require("moment/min/locales.min");

const getPickerProps = (showTime) => {
    const dateFormat = "YYYY-MM-DD";
    const displayDateFormat = "DD/MM/YYYY";
    return ({
        component: showTime ? DateTimePicker : DatePicker,
        format: showTime ? `${dateFormat} HH:mm:SS` : dateFormat,
        displayFormat: showTime ? `${displayDateFormat} HH:mm:SS` : displayDateFormat
    });
};

const getValue = (value) => {
    return (value) ? value : null;
};

const getHelperText = (error, helperText, errorMeta) => (error || errorMeta || helperText);
const getKeyBoardIconProps = (variant) => ((variant !== 'standard') ? {style: {marginRight: -12, padding: 6}} : {});

function handleChange(date, format, setErrorAction, onChange, translate, invalidDateRequest) {
    if (moment(date).isValid() || date == null) {
        setErrorAction('');
        const d = date ? moment(date).format(format) : null;
        onChange(d);
    } else {
        if (invalidDateRequest) {
            const d = date ? moment(date).format(format) : null;
            onChange(d);
        }
        setErrorAction(translate('ra.validation.invalid_date'))
    }
}

const DateInputPicker = (props) => {
    const translate = useTranslate();
    const {
        input: {name, onChange, value},
        meta
    } = useInput(props);
    const {
        label,
        showTime,
        clearable,
        autoOk,
        customLocale,
        style,
        variant,
        margin,
        helperText,
        invalidDateRequest,
        firstSelectableDate
    } = props;
    const locale = localStorage.getItem(LANGUAGE);
    const [error, setError] = useState('');
    const pickerProps = getPickerProps(showTime);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}
                                 locale={moment.locale(customLocale ? customLocale : locale)}>
            <pickerProps.component
                style={style}
                autoOk={autoOk}
                label={<FieldTitle
                    label={label}
                    source={name}
                    resource={props.resource}
                />}
                value={getValue(value)}
                onChange={(date) => {
                    handleChange(date, pickerProps.format, setError, onChange, translate, invalidDateRequest);
                }}
                format={pickerProps.displayFormat}
                margin={margin}
                clearable={clearable}
                clearLabel={translate('ra.action.clear')}
                error={!!error || !!(meta.touched && meta.error)}
                helperText={getHelperText(error, helperText, meta.error)}
                KeyboardButtonProps={getKeyBoardIconProps(variant)}
                inputVariant={variant}
                shouldDisableDate={(d) => (firstSelectableDate && d < firstSelectableDate)}
            />
        </MuiPickersUtilsProvider>
    );
};


DateInputPicker.propTypes = {
    label: PropTypes.string,
    options: PropTypes.object,
    source: PropTypes.string,
    showTime: PropTypes.bool,
    input: PropTypes.object,
    clearable: PropTypes.bool,
    autoOk: PropTypes.bool,
    customLocale: PropTypes.string,
    style: PropTypes.object,
    margin: PropTypes.string,
    variant: PropTypes.string,
    invalidDateRequest: PropTypes.bool,
    firstSelectableDate: PropTypes.object
};

DateInputPicker.defaultProps = {
    showTime: false,
    clearable: false,
    autoOk: true,
    customLocale: null,
    style: {},
    variant: 'standard',
    margin: 'dense',
    firstSelectableDate: null,
    invalidDateRequest: true
};

export default (DateInputPicker);
