import {CHANGE_FONT_SIZE} from '../actions/theme';

const initialState = {
    custom: {
        fontSize: 12
    }
};

export default (prevState = initialState, {type, payload}) => {
    if (type === CHANGE_FONT_SIZE) {
        return {...prevState, ...{custom: payload}};
    } else {
        return prevState;
    }
}
