import React from 'react';
import {Form} from 'react-final-form';
import {CustomFormInput, FormStateProvider} from "./";
import {mutatorActions} from "../CommonUtils";

const CustomForm = ({
                        submit,
                        initialValues,
                        validate,
                        mutators,
                        subscription,
                        children,
                        sharedFormState,
                        variant,
                        record,
                        ...props
                    }) => (
    <Form
        onSubmit={submit}
        initialValues={initialValues}
        validate={validate}
        mutators={{
            ...mutatorActions(React.Children.map(children, input => input.props.source)),
            ...mutators
        }}
        subscription={subscription}
        {...props}
    >
        {({handleSubmit, values, form}) => {
            return (
                <form onSubmit={handleSubmit} className={props.className}>
                    {React.Children.map(children, input => (
                        <CustomFormInput
                            input={input}
                            record={values}
                            variant={variant}
                        />
                    ))}
                    <FormStateProvider
                        setStateAction={sharedFormState}
                        formApi={form}
                        submitAction={handleSubmit}
                    />
                </form>
            )
        }}
    </Form>
);

export default CustomForm;
