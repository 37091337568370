import React from 'react';
import {Datagrid, Filter, List, SelectField, TextField, TextInput, useTranslate} from 'react-admin';
import {goServiceTrips} from "../components/CommonUtils";
import TrueIcon from '@material-ui/icons/Check';


const Filters = (props) => (
    <Filter {...props} variant="outlined">
        <TextInput source={'q'} alwaysOn resettable/>
    </Filter>
);

const booleanChoice = [
    {id: true, name: <TrueIcon/>},
    {id: false, name: ''},
];

export const UiServices = (props) => {
    const translate = useTranslate();
    return (
        <List
            title={translate('resources.services.name')}
            actions={null}
            filters={<Filters/>}
            bulkActionButtons={false}
            sort={{
                field: 'id',
                order: 'ASC'
            }}
            {...props}
        >
            <Datagrid rowClick={goServiceTrips}>
                <TextField source={'id'}/>
                <TextField source={'shortDescription'}/>
                <TextField source={'description'}/>
                <SelectField source={'reservationRequired'} translateChoice={false} choices={booleanChoice}/>
            </Datagrid>
        </List>
    );
};
