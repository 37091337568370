import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {Notification, useLogin, useNotify, useTranslate} from 'react-admin';
import BusIcon from '@material-ui/icons/DirectionsBus';
import Background from './backgrounds/ATVOBackground.jpg';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
        backgroundColor: '#ffffff',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.grey[500],
        width: '2em',
        height: '2em',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    }
}));

const getText = (error) => {
    let txt;
    if (typeof error === 'string') {
        txt = error;
    } else {
        txt = (typeof error === 'undefined' || !error.message) ? 'ra.auth.sign_in_error' : error.message
    }
    return txt;
};

const renderInput = ({
                         meta: {touched, error} = {},
                         input: {...inputProps},
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const Login = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const login = useLogin();
    const notify = useNotify();
    const [isLoading, setLoading] = useState(false);

    const loginAction = (auth) => {
        setLoading(true);
        login(auth, '/')
            .catch(error => {
                setLoading(false);
                notify(
                    getText(error),
                    'warning'
                )
            })
    };

    return (
        <div className={classes.main}>
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                        <BusIcon/>
                    </Avatar>
                </div>
                <Form
                    onSubmit={loginAction}
                    validate={(values) => {
                        const errors = {};
                        if (!values.username) {
                            errors.username = translate('ra.validation.required');
                        }
                        if (!values.password) {
                            errors.password = translate('ra.validation.required');
                        }
                        return errors;
                    }}
                >
                    {({handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        name="username"
                                        component={renderInput}
                                        label={translate('ra.auth.username')}
                                        disabled={isLoading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        component={renderInput}
                                        label={translate('ra.auth.password')}
                                        type="password"
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={isLoading}
                                    fullWidth
                                >
                                    {isLoading && (
                                        <CircularProgress size={25} thickness={2}/>
                                    )}
                                    {translate('ra.auth.sign_in')}
                                </Button>
                            </CardActions>
                        </form>
                    )}
                </Form>
            </Card>
            <Notification/>
        </div>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    previousRoute: PropTypes.string,
};


export default Login;
