import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {NotifyConfirm} from '..';
import IconedButton from "./IconedButton";

const ConfirmDialogButton = (props) => {
    const [isOpen, setOpen] = useState(false);

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleDialogOpen = () => {
        setOpen(true);
    };

    return (
        <React.Fragment>
            <IconedButton
                buttonType={props.buttonType}
                label={props.buttonLabel}
                style={props.buttonStyle}
                handleClick={handleDialogOpen}/>
            <NotifyConfirm
                isOpen={isOpen}
                title={props.dialogTitle}
                content={props.dialogContent}
                confirm={props.dialogConfirmLabel}
                cancel={props.dialogCancelLabel}
                onConfirm={() => {
                    handleDialogClose();
                    props.onConfirm(props);
                }}
                onClose={handleDialogClose}
            />
        </React.Fragment>
    )
};

ConfirmDialogButton.propTypes = {
    buttonType: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonStyle: PropTypes.object,
    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.string,
    onConfirm: PropTypes.func,
    dialogCancelLabel: PropTypes.string,
    dialogConfirmLabel: PropTypes.string,
};

ConfirmDialogButton.defaultProps = {};

export default ConfirmDialogButton;


