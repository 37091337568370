import React from 'react';
import PropTypes from 'prop-types';
import {Chip} from '@material-ui/core';

const ChipArrayField = ({record, source, ...props}) => {
    return <div>
        {record[source] && record[source].split(",").map((chip, key) => (
            <Chip label={chip} key={key} style={{margin: 2}}/>
        ))}
    </div>
};

ChipArrayField.propTypes = {
    source: PropTypes.string
};

export default ChipArrayField;
