import React, {Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {AppBar as MuiAppBar, IconButton, Toolbar, Typography, withStyles, withWidth} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import compose from 'recompose/compose';
import {
    HideOnScroll,
    LoadingIndicator,
    toggleSidebar as toggleSidebarAction,
    UserMenu,
    withTranslate
} from 'react-admin';
import {SESSION_ACCOUNT_NAME} from "./authClient";

const styles = theme => ({
    toolbar: {
        paddingRight: 24,
    },
    menuButton: {
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
    menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
});

const AppBar = ({
                    children,
                    classes,
                    className,
                    logo,
                    logout,
                    open,
                    title,
                    toggleSidebar,
                    userMenu,
                    width,
                    translate,
                    ...rest
                }) => (
    <HideOnScroll>
        <MuiAppBar
            className={className}
            color="secondary"
            position="fixed"
            {...rest}
        >
            <Toolbar
                disableGutters
                variant={width === 'xs' ? 'regular' : 'dense'}
                className={classes.toolbar}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleSidebar}
                    className={classNames(classes.menuButton)}
                >
                    <MenuIcon
                        classes={{
                            root: open
                                ? classes.menuButtonIconOpen
                                : classes.menuButtonIconClosed,
                        }}
                    />
                </IconButton>
                {Children.count(children) === 0 ? (
                    <Typography
                        variant="h5"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                ) : (
                    children
                )}
                <Typography variant={"subtitle1"} color={"inherit"}>
                    {translate("appBar.userText", {accountName: localStorage.getItem(SESSION_ACCOUNT_NAME)})}
                </Typography>
                <LoadingIndicator/>
                {cloneElement(userMenu, {logout})}
            </Toolbar>
        </MuiAppBar>
    </HideOnScroll>
);

AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    userMenu: PropTypes.node,
    width: PropTypes.string,
};

AppBar.defaultProps = {
    userMenu: <UserMenu/>,
};

const enhance = compose(
    connect(null, {toggleSidebar: toggleSidebarAction}),
    withStyles(styles),
    withWidth(),
    withTranslate
);

export default enhance(AppBar);
