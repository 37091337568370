import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme, IconButton, makeStyles, MuiThemeProvider} from '@material-ui/core';
import {Calendar as MuiCalendar, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import classnames from 'classnames';
import {LANGUAGE} from "../authClient";

require("moment/min/locales.min");

const NO_TRANSFORM = 'none !important';

const customTheme = createMuiTheme({
    overrides: {
        MuiPickersSlideTransition: {
            'slideEnter-left': {
                opacity: 0,
                transition: 'opacity 100ms ease-in 100ms',
                transform: NO_TRANSFORM,
            },
            slideExit: {
                opacity: 1,
                transition: 'opacity 100ms ease-out 0ms',
                transform: NO_TRANSFORM,
            },
            slideEnterActive: {
                opacity: 0,
                transition: 'opacity 100ms ease-in 100ms',
                transform: NO_TRANSFORM,
            },
            'slideExitActiveLeft-left': {
                opacity: 1,
                transition: 'opacity 100ms ease-out 0ms',
                transform: NO_TRANSFORM,
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "1px 2px",
        color: "inherit",
        '&:hover': {
            backgroundColor: 'inherit',
            cursor: 'default'
        }
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    }
}));

const Calendar = (props) => {
    const {calendarDays, month, customLocale, year} = props;

    const classes = useStyles();
    const [locale, setLocale] = useState('it');

    useEffect(() => {
        setLocale(localStorage.getItem(LANGUAGE));
    }, []);

    const renderDay = (date, selectedDate, dateInCurrentMonth) => {
        const searchDate = moment(date).format('YYYY-MM-DD');

        return (
            <IconButton className={classnames({
                [classes.day]: true,
                [classes.highlight]: (calendarDays.includes(searchDate) && dateInCurrentMonth),
            })}>
                <span> {dateInCurrentMonth && moment(date).get('date')} </span>
            </IconButton>
        );
    };

    return (
        <MuiThemeProvider theme={customTheme}>
            <div id={`calendar-${month}`}>
                <MuiPickersUtilsProvider utils={MomentUtils}
                                         locale={moment.locale(customLocale ? customLocale : locale)}>
                    <MuiCalendar
                        rightArrowButtonProps={{style: {visibility: 'hidden'}}}
                        leftArrowButtonProps={{style: {visibility: 'hidden'}}}
                        renderDay={renderDay}
                        date={moment().startOf("month").set('month', month).set('year', year)}
                        onChange={() => {
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </MuiThemeProvider>
    );
};

Calendar.propTypes = {
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    calendarDays: PropTypes.arrayOf(PropTypes.string).isRequired,
    customLocale: PropTypes.string
};

Calendar.defaultProps = {
    customLocale: null
};

export default (Calendar);
