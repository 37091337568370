import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withStyles} from '@material-ui/core';
import {NumberField} from "react-admin";

const coloredStyles = () => ({
    red: {
        color: 'red',
    },
    green: {
        color: 'green',
    },
});

const getClassname = (classes, value, colored) => {
    if (colored) {
        return classnames({
            [classes.red]: value < 0,
            [classes.green]: value >= 0,
        });
    } else {
        return null;
    }
};

const CustomNumberField = withStyles(coloredStyles)(
    ({classes, record, colored, source, options, base100, ...rest}) => {
        const value = record[source];
        const newRecord = {};
        newRecord[source] = value;
        if (options && options.style === 'percent' && base100) {
            newRecord[source] = value / 100;
        }

        return (
            <NumberField
                className={getClassname(classes, value, colored)}
                record={newRecord}
                source={source}
                options={options}
                {...rest}
            />
        )
    });

CustomNumberField.defaultProps = {
    colored: false,
    base100: false,
    addLabel: true,
    textAlign: 'right'
};

CustomNumberField.propTypes = {
    colored: PropTypes.bool,
    base100: PropTypes.bool
};

export default CustomNumberField;
