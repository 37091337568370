import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {SelectInput, useDataProvider, useTranslate} from 'react-admin'
import {KDialogTitle} from './';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {range} from 'lodash';
import {stringify} from 'query-string';
import {LANGUAGE} from "../authClient";
import Download from "./Download";
import {
    defaultFormState,
    getAgencies,
    getAgents,
    getAvailableYears,
    getExportVariants,
    getFirstElement,
    noAction,
    renderMenuItems,
    useExportDialogStyle
} from "./CommonUtils";
import {CustomSelectInput} from "./input";
import {CustomForm} from "./form";

const initialValues = {
    agencyId: null,
    agentId: null,
    month: null,
    year: null
};

const validator = (translate) => (values) => {
    const errors = {};
    ['agencyId', 'month', 'year'].forEach(field => {
        if (!values[field]) {
            errors[field] = translate('ra.validation.required');
        }
    });
    return errors;
};

function getTitle(file) {
    let title = `dialogExportReport.defaultTitle`;
    if (file) {
        title = `reports.files.${file}`;
    }
    return title
}

function getPath(f, variant, values) {
    const {agencyId, agentId, month, year} = values;
    return `genericBookingsReport?${stringify({
        selectedId: variant === 'agent' ? agentId : agencyId,
        month: month,
        year: year,
        report: f,
        variant: variant
    })}`;
}

function getDescString(variant, agencies, agents, values) {
    let desc = "";
    const {agencyId, agentId, month, year} = values;
    if (variant === 'agency') {
        const agencyIndex = agencies.findIndex(x => x.id === agencyId);
        if (agencyIndex !== -1) {
            desc = `${agencies[agencyIndex].name} `;
        }
    } else {
        const agentIndex = agents.findIndex(x => x.id === agentId);
        if (agentIndex !== -1) {
            desc = `${agents[agentIndex].name} `;
        }
    }
    const monthYear = moment().set({'year': year, 'month': month - 1, 'date': 1});
    desc = desc + monthYear.format('MMMM YYYY');
    return desc;
}

const DialogExportBookingsReport = (props) => {
    const {open, file, closeDialog} = props;

    const classes = useExportDialogStyle();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const filename = `reports.files.${file}`;
    const filter = (x) => x.id > 3;

    const [agencies, setAgencies] = useState([]);
    const [agents, setAgents] = useState([]);
    const [exportVariants, setExportVariants] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [formState, setFormState] = useState(defaultFormState);
    const {api: {mutators}, values, valid} = formState;
    const {agencyId, agentId} = values;

    useEffect(() => {
        if (open) {
            moment.locale(localStorage.getItem(LANGUAGE));
            getAgencies(dispatch, dataProvider, setAgencies, filter);
            getExportVariants(dispatch, dataProvider, setExportVariants, file);
            getAvailableYears(dispatch, dataProvider, setYears);

            const tmp = [];
            range(0, 12).forEach(val => {
                tmp.push({id: val + 1, name: moment().month(val).format("MMMM")})
            });
            setMonths(tmp);
        }
    }, [open, file, dispatch, dataProvider]);

    useEffect(() => {
        if (mutators && years.length > 0) {
            mutators.changeYear(moment().year());
            mutators.changeMonth(moment().month() + 1);
        }
    }, [years, mutators]);

    useEffect(() => {
        if (agencyId) {
            getAgents(dispatch, dataProvider, setAgents, agencyId);
        }
    }, [dispatch, dataProvider, agencyId, agencies]);

    useEffect(() => {
        mutators && mutators.changeAgencyId(getFirstElement(agencies, agencies.length > 0));
    }, [agencies, mutators]);

    return (
        <React.Fragment>
            {open &&
            <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
                <KDialogTitle handleClick={closeDialog} title={translate(getTitle(file))}/>
                <DialogContent>
                    <CustomForm
                        sharedFormState={setFormState}
                        initialValues={initialValues}
                        validate={validator(translate)}
                        onSubmit={noAction}
                        variant={'standard'}
                        className={classes.form}
                    >
                        <SelectInput
                            label={translate('dialogExportReport.input.agency')}
                            source={'agencyId'}
                            className={classes.field}
                            choices={agencies}
                            translateChoice={false}
                        />
                        <CustomSelectInput
                            className={classes.field}
                            label={translate('dialogExportReport.input.agent')}
                            source={'agentId'}
                            renderMenuFunction={renderMenuItems}
                            subHeaderMenu={agents.length > 10}
                            choices={agents}
                            translateChoice={false}
                            oneItemReadOnly={true}
                        />
                        <SelectInput
                            className={classes.field}
                            label={translate('dialogExportReport.input.month')}
                            source={'month'}
                            choices={months}
                            translateChoice={false}
                        />
                        <SelectInput
                            className={classes.field}
                            label={translate('dialogExportReport.input.year')}
                            source={'year'}
                            choices={years}
                            translateChoice={false}
                        />
                    </CustomForm>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    {file && exportVariants.map(variant => (
                        (variant !== 'agent' || agents.length > 1) && <Download
                            key={variant}
                            label={`dialogExportReport.generate.${variant}`}
                            path={getPath(file, variant, values)}
                            filename={`${translate(filename)}-${getDescString(
                                variant, agencies, agents, values)}.xlsx`}
                            disabled={!valid || ((variant === 'agent') && !agentId)}
                        />))
                    }
                </DialogActions>
            </Dialog>}
        </React.Fragment>
    );
};

DialogExportBookingsReport.propTypes = {
    open: PropTypes.bool.isRequired,
    file: PropTypes.string,
    closeDialog: PropTypes.func.isRequired,
};

DialogExportBookingsReport.defaultProps = {
    open: false
};

export default (DialogExportBookingsReport);
