import React from 'react';
import {useTranslate} from 'react-admin';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';
import {IconedButton} from "./buttons";

const DEFAULT_CLASSNAME = 'ra-confirm';

const useStyles = makeStyles(theme => ({
    confirmPrimary: {
        color: theme.palette.primary.main,
    },
    confirmWarning: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
}));

/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
const NotifyConfirm = ({
                           isOpen,
                           title,
                           content,
                           confirm,
                           cancel,
                           confirmColor,
                           onConfirm,
                           onClose,
                           disableOnLoad
                       }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {onConfirm && (
                    <React.Fragment>
                        <IconedButton
                            handleClick={onClose}
                            label={translate(cancel)}
                            buttonType={'cancelButton'}
                            disableOnLoad={disableOnLoad}
                            className={classnames(DEFAULT_CLASSNAME, {
                                [classes.confirmWarning]: confirmColor === 'warning',
                                [classes.confirmPrimary]: confirmColor === 'primary'
                            })}/>
                        <IconedButton
                            handleClick={onConfirm}
                            className={classnames(DEFAULT_CLASSNAME, {
                                [classes.confirmWarning]: confirmColor === 'warning',
                                [classes.confirmPrimary]: confirmColor === 'primary'
                            })}
                            disableOnLoad={disableOnLoad}
                            buttonType={'okButton'}
                            label={translate('confirm')}/>
                    </React.Fragment>
                )}
                {!onConfirm && (
                    <React.Fragment>
                        <IconedButton
                            handleClick={onClose}
                            className={classnames(DEFAULT_CLASSNAME, {
                                [classes.confirmWarning]: confirmColor === 'warning',
                                [classes.confirmPrimary]: confirmColor === 'primary',
                            })}
                            buttonType={'okButton'}
                            label={translate(confirm)}
                            disableOnLoad={disableOnLoad}
                        />
                    </React.Fragment>
                )}
            </DialogActions>
        </Dialog>
    );
};

NotifyConfirm.propTypes = {
    cancel: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    confirm: PropTypes.string,
    confirmColor: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    title: PropTypes.string.isRequired,
    disableOnLoad: PropTypes.bool
};

NotifyConfirm.defaultProps = {
    cancel: 'ra.action.cancel',
    classes: {},
    confirm: 'ra.action.ok',
    confirmColor: 'primary',
    isOpen: false,
    disableOnLoad: false
};

export default NotifyConfirm;
