import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Card, LinearProgress} from '@material-ui/core';
import {getLineChart} from '../../actions/dashboard';
import {withTranslate} from 'react-admin';
import compose from 'recompose/compose';

const ReactHighstock = require('react-highcharts/ReactHighstock.src');
const HighchartsMore = require('highcharts-more');
HighchartsMore(ReactHighstock.Highcharts);

const timeFormats = {
    today: '%A, %B %e, %H:%M',
    other: '%A, %B %e'
};

const chartConfig = (name, period) => ({
    rangeSelector: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    chart: {
        height: 292
    },
    colors: ['#2196F3', '#E91E63'],
    series: [{
        name: name,
        data: [],
        type: 'spline',
        tooltip: {
            valueDecimals: 0
        }
    }],
    time: {
        useUTC: false
    },
    tooltip: {
        split: true,
        dateTimeLabelFormats: {
            millisecond: timeFormats.other,
            second: (period === 24) ? timeFormats.today : timeFormats.other,
            minute: (period === 24) ? timeFormats.today : timeFormats.other,
            hour: (period === 24) ? timeFormats.today : timeFormats.other
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true
            }
        }
    }
});

const styles = {
    cardContent: {
        padding: 10,
    },
    select: {
        width: 'auto',
        minWidth: 160,
        marginLeft: 20,
    },
};

class LineChart extends Component {

    componentDidMount() {
        const {getLineChart, agency, period} = this.props;
        getLineChart(agency, period);
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const {getLineChart, agency, period, isLoading} = this.props;
        const chart = this.refs.chart && this.refs.chart.getChart();
        if (agency !== prevProps.agency || period !== prevProps.period) {
            getLineChart(agency, period);
        } else if (chart && !isLoading) {
            chart.reflow = () => {
            };
        }
    }

    render() {
        const {isLoading, data, translate, period} = this.props;
        ReactHighstock.Highcharts.setOptions({
            lang: {
                weekdays: translate('dashboard.lineChart.weekdays').split(','),
                months: translate('dashboard.lineChart.months').split(','),
                shortMonths: translate('dashboard.lineChart.shortMonths').split(',')
            }
        });
        const config = {...chartConfig(translate('dashboard.lineChart.title'), period)};
        config.series[0].data = data ? data : [];

        return (
            <Card style={{height: '100%'}}>
                <div style={{position: 'relative'}}>
                    {isLoading && <LinearProgress mode="indeterminate" style={{position: 'absolute'}}/>}
                    <div style={styles.cardContent}>
                        {data && <ReactHighstock config={config} ref="chart"/>}
                    </div>
                </div>
            </Card>
        );
    }
}

LineChart.propTypes = {
    agency: PropTypes.number,
    period: PropTypes.number,
};

const mapStateToProps = state => {
    let stateToProps = {};
    if (state.dashboard && state.dashboard.lineChart) {
        stateToProps = {
            isLoading: state.dashboard.lineChart.isLoading,
            data: state.dashboard.lineChart.data
        }
    }
    return stateToProps;
};

const enhance = compose(
    withTranslate,
    connect(mapStateToProps, {getLineChart})
);

export default enhance(LineChart);
