import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import {makeStyles} from '@material-ui/core/styles';
import {sanitizeListRestProps} from 'ra-core';
import CardElement from './CardElement';

const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    card: {
        width: '250px !important',
        height: '150px !important',
        border: '1px solid lightgrey',
        margin: '20px',
        float: 'left',
    },
    primaryLeft: {
        fontSize: 16,
        paddingLeft: 10,
        paddingBottom: 5,
        fontWeight: 'bold',
        width: 115,
        color: '#333'
    },
    primaryRight: {
        fontSize: 16,
        fontWeight: 'bold',
        paddingRight: 10,
        paddingBottom: 5,
        width: 115,
        textAlign: 'right',
        color: '#333'
    },
    secondaryLeft: {
        fontSize: 12,
        paddingLeft: 10,
        paddingBottom: 5,
        width: 150,
        color: '#333'
    },
    secondaryRight: {
        fontSize: 12,
        paddingRight: 10,
        paddingBottom: 5,
        textAlign: 'right',
        color: '#333'
    },
    tertiaryLeft: {
        fontSize: 12,
        paddingLeft: 10,
        width: 150,
        color: '#333'
    },
    tertiaryRight: {
        fontSize: 12,
        paddingRight: 10,
        textAlign: 'right',
        color: '#333'
    }

});

const CardDatagrid = ({
                          basePath,
                          className,
                          data,
                          hasBulkActions,
                          ids,
                          isLoading,
                          linkType,
                          onToggleItem,
                          onSelect,
                          onUnselectItems,
                          primaryLeftText,
                          primaryRightText,
                          secondaryLeftText,
                          secondaryRightText,
                          tertiaryLeftText,
                          tertiaryRightText,
                          primaryColSpan,
                          secondaryColSpan,
                          tertiaryColSpan,
                          linkQueryString,
                          executeFunction,
                          selectedIds,
                          total,
                          elementToolbar,
                          ...rest
                      }) => {
    const classes = useStyles();
    return ((isLoading || total > 0) && (
        <List
            className={className} {...sanitizeListRestProps(rest)}>
            {ids.map(id => (

                <CardElement
                    basePath={basePath}
                    className={classes.card}
                    classes={classes}
                    id={id}
                    key={id}
                    data={data}
                    linkType={linkType}
                    primaryLeftText={primaryLeftText}
                    primaryRightText={primaryRightText}
                    secondaryLeftText={secondaryLeftText}
                    secondaryRightText={secondaryRightText}
                    tertiaryLeftText={tertiaryLeftText}
                    tertiaryRightText={tertiaryRightText}
                    primaryColSpan={primaryColSpan}
                    secondaryColSpan={secondaryColSpan}
                    tertiaryColSpan={tertiaryColSpan}
                    linkQueryString={linkQueryString}
                    executeFunction={executeFunction}
                    toolbar={elementToolbar}
                    onToggleItem={onToggleItem}
                    onSelect={onSelect}
                    onUnselectItems={onUnselectItems}
                    selected={selectedIds.includes(id)}
                    hasBulkActions={hasBulkActions}
                />
            ))}
        </List>
    ))

};

CardDatagrid.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    hasBulkActions: PropTypes.bool.isRequired,
    ids: PropTypes.array,
    linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        .isRequired,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    onUnselectItems: PropTypes.func,
    primaryColSpan: PropTypes.bool,
    secondaryColSpan: PropTypes.bool,
    tertiaryColSpan: PropTypes.bool,
    primaryLeftText: PropTypes.func,
    primaryRightText: PropTypes.func,
    secondaryLeftText: PropTypes.func,
    secondaryRightText: PropTypes.func,
    tertiaryLeftText: PropTypes.func,
    tertiaryRightText: PropTypes.func,
    linkQueryString: PropTypes.func,
    executeFunction: PropTypes.func,
    elementToolbar: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

CardDatagrid.defaultProps = {
    linkType: 'edit',
    hasBulkActions: false,
    selectedIds: [],
    elementToolbar: null,
};

export default (CardDatagrid);
