import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {SelectInput, useDataProvider, useTranslate} from 'react-admin'
import {Download, KDialogTitle} from './';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {range} from 'lodash';
import {stringify} from 'query-string';
import {LANGUAGE} from "../authClient";
import {defaultFormState, getAvailableYears, getExportVariants, noAction, useExportDialogStyle} from "./CommonUtils";
import {CustomForm} from "./form";

const initialFormValues = {
    year: null,
    month: null
};

function getDescString(values) {
    const {year, month} = values;
    const monthYear = moment().set({'year': year, 'month': month - 1, 'date': 1});
    return monthYear.format('MMMM YYYY');
}

function getTitle(file) {
    let title = `dialogExportReport.defaultTitle`;
    if (file) {
        title = `reports.files.${file}`;
    }
    return title
}

function getPath(f, variant, values) {
    const {year, month} = values;
    return `agencyReport?${stringify({
        month: month,
        year: year,
        report: f,
        variant: variant
    })}`;
}

const DialogExportReport = (props) => {
    const {open, file, closeDialog} = props;

    const classes = useExportDialogStyle();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const filename = `reports.files.${file}`;

    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const [exportVariants, setExportVariants] = useState([]);
    const [formState, setFormState] = useState(defaultFormState);
    const {api: {mutators}, values} = formState;

    useEffect(() => {
        if (open) {
            moment.locale(localStorage.getItem(LANGUAGE));
            getAvailableYears(dispatch, dataProvider, setYears);
            getExportVariants(dispatch, dataProvider, setExportVariants, file);

            const tmp = [];
            range(0, 12).forEach(val => (
                tmp.push({id: val + 1, name: moment().month(val).format('MMMM')})
            ));
            setMonths(tmp);
        }
    }, [open, file, dispatch, dataProvider]);

    useEffect(() => {
        if (mutators && years.length > 0) {
            mutators.changeYear(moment().year());
            mutators.changeMonth(moment().month() + 1);
        }
    }, [years, mutators]);

    return (
        <React.Fragment>
            {open &&
            <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
                <KDialogTitle handleClick={closeDialog} title={translate(getTitle(file))}/>
                <DialogContent>
                    <CustomForm
                        submit={noAction}
                        initialValues={initialFormValues}
                        sharedFormState={setFormState}
                        variant={'standard'}
                        className={classes.form}
                    >
                        <SelectInput label={translate('dialogExportReport.input.month')} source={'month'}
                                     translateChoice={false} choices={months}/>
                        <SelectInput label={translate('dialogExportReport.input.year')} source={'year'}
                                     translateChoice={false} choices={years}/>
                    </CustomForm>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    {file && exportVariants.map(variant => <Download
                        key={variant}
                        label={`dialogExportReport.generate.${variant}`}
                        path={getPath(file, variant, values)}
                        filename={`${translate(filename)}-${getDescString(values)}.xlsx`}
                    />)}
                </DialogActions>
            </Dialog>}
        </React.Fragment>
    );
};

DialogExportReport.propTypes = {
    open: PropTypes.bool.isRequired,
    file: PropTypes.string,
    closeDialog: PropTypes.func.isRequired,
};

DialogExportReport.defaultProps = {
    open: false
};

export default (DialogExportReport);
