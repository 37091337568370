import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Card, LinearProgress, MenuItem, TextField, withStyles} from '@material-ui/core';
import {getCompareBarChart, setCompareBarChartFilter} from '../../actions/dashboard';
import ReactHighcharts from 'react-highcharts';
import {fetchEnd, fetchError, fetchStart, withTranslate} from 'react-admin';
import compose from 'recompose/compose';
import moment from 'moment';
import {range} from 'lodash';
import {LANGUAGE} from "../../authClient";

const chartConfig = (type) => ({
    title: {
        text: null
    },
    credits: {
        enabled: false
    },
    colors: ['#FF5722', '#607D8B', '#2196F3', '#4CAF50', '#FFC107', '#9C27B0', '#795548', '#673AB7', '#009688', '#E91E63'],
    chart: {
        type: 'column',
        height: 290
    },
    series: [{
        name: '',
        data: []
    }],
    tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true,
        ...(type === 'values') ? {valueDecimals: 2, valueSuffix: ' €'} : {}
    },
});


const styles = {
    cardContent: {
        padding: 10,
    },
    filterContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    leftSelect: {
        width: 'auto',
        minWidth: 120,
        marginLeft: 20,
    },
    rightSelect: {
        width: 'auto',
        minWidth: 120,
        marginRight: 20
    },
    rightContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end'
    }
};

class CompareBarChart extends Component {

    componentDidMount() {
        const {getCompareBarChart, type, filters: {yearA, yearB, monthA, monthB}} = this.props;
        getCompareBarChart(type, yearA, yearB, monthA, monthB);
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const {getCompareBarChart, type, filters, isLoading} = this.props;
        const chart = this.refs.chart && this.refs.chart.getChart();
        if (filters !== prevProps.filters) {
            const {yearA, yearB, monthA, monthB} = filters;
            getCompareBarChart(type, yearA, yearB, monthA, monthB);
        } else if (chart && !isLoading) {
            chart.reflow = () => {
            };
        }
    }

    changeYearA = (val) => {
        const {type, setCompareBarChartFilter, filters: {yearB, monthA, monthB}} = this.props;
        setCompareBarChartFilter(type, val, yearB, monthA, monthB);
    };

    changeYearB = (val) => {
        const {type, setCompareBarChartFilter, filters: {yearA, monthA, monthB}} = this.props;
        setCompareBarChartFilter(type, yearA, val, monthA, monthB);
    };

    changeMonthA = (val) => {
        const {type, setCompareBarChartFilter, filters: {yearA, yearB, monthB}} = this.props;
        if (val === 0) {
            setCompareBarChartFilter(type, yearA, yearB, val, 0);
        } else {
            setCompareBarChartFilter(type, yearA, yearB, val, monthB);
        }
    };

    changeMonthB = (val) => {
        const {type, setCompareBarChartFilter, filters: {yearA, yearB, monthA}} = this.props;
        if (val === 0) {
            setCompareBarChartFilter(type, yearA, yearB, 0, val);
        } else {
            setCompareBarChartFilter(type, yearA, yearB, monthA, val);
        }
    };

    render() {
        const {isLoading, data, type, translate, filters: {yearA, yearB, monthA, monthB}, classes} = this.props;
        moment.locale(localStorage.getItem(LANGUAGE));
        const series = [];
        if (data && data.series) {
            data.series.forEach(serie => {
                series.push({
                    name: (serie.name.includes('-')) ? moment(serie.name, "YYYY-M").format('MMMM YYYY') : serie.name,
                    data: serie.data,
                    _colorIndex: serie._colorIndex
                })
            })
        }
        const barChartConfig = {
            xAxis: {categories: data && data.keys ? data.keys : []},
            yAxis: {title: {text: translate(`dashboard.compareChart.yAxisTitles.${type}`)}},
            series: series
        };
        let year;
        return (
            <Card style={{height: '100%'}}>
                <div style={{position: 'relative'}}>
                    {isLoading && <LinearProgress mode="indeterminate" style={{position: 'absolute'}}/>}
                    <div className={classes.cardContent}>
                        <div className={classes.filterContainer}>
                            <div>
                                <TextField
                                    value={yearA}
                                    onChange={(e) => this.changeYearA(e.target.value)}
                                    margin={'normal'}
                                    className={classes.leftSelect}
                                    select
                                >
                                    {range(0, 6).map(i => {
                                        year = moment().subtract(i, 'year').year();
                                        return <MenuItem key={i} value={year}>{year}</MenuItem>
                                    })}
                                </TextField>
                                <TextField
                                    value={monthA}
                                    onChange={(e) => this.changeMonthA(e.target.value)}
                                    margin={'normal'}
                                    className={classes.leftSelect}
                                    select
                                >
                                    <MenuItem value={0}/>
                                    {range(1, 13).map(i => <MenuItem value={i}
                                                                     disabled={yearA === yearB && monthB === i}
                                                                     key={i}>{moment().month(i - 1).format('MMMM')}</MenuItem>)}
                                </TextField>
                            </div>
                            <div className={classes.rightContainer}>
                                <TextField
                                    value={yearB}
                                    onChange={(e) => this.changeYearB(e.target.value)}
                                    margin={'normal'}
                                    className={classes.rightSelect}
                                    select
                                >
                                    {range(0, 6).map(i => {
                                        year = moment().subtract(i, 'year').year();
                                        return <MenuItem key={i} value={year}>{year}</MenuItem>
                                    })}
                                </TextField>
                                <TextField
                                    value={monthB}
                                    onChange={(e) => this.changeMonthB(e.target.value)}
                                    margin={'normal'}
                                    className={classes.rightSelect}
                                    select
                                >
                                    <MenuItem value={0}/>
                                    {range(1, 13).map(i => <MenuItem disabled={yearA === yearB && monthA === i}
                                                                     key={i}
                                                                     value={i}>{moment().month(i - 1).format('MMMM')}</MenuItem>)}
                                </TextField>
                            </div>
                        </div>
                        {(data && barChartConfig.series.length > 0) &&
                        <ReactHighcharts config={{...chartConfig(type), ...barChartConfig}} ref="chart"/>}
                    </div>
                </div>
            </Card>
        );
    }
}

CompareBarChart.propTypes = {
    type: PropTypes.oneOf(["tickets", "values"]).isRequired
};

const mapStateToProps = (state, props) => {
    if (state.dashboard && state.dashboard.compareChart && state.dashboard.compareChart[props.type]) {
        return {
            filters: state.dashboard.compareChart[props.type].filters,
            isLoading: state.dashboard.compareChart[props.type].isLoading,
            data: state.dashboard.compareChart[props.type].data,
        }
    } else {
        return null
    }
};

const mapDispatchToProps = {
    getCompareBarChart,
    setCompareBarChartFilter,
    fetchStart,
    fetchEnd,
    fetchError
};

const enhance = compose(
    withTranslate,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(CompareBarChart);
