import {createMuiTheme} from '@material-ui/core';

export const TaBrokerTheme = (fontSize) => createMuiTheme({
    palette: {
        secondary: {
            main: '#2196f3'
        }
    },
    typography: {
        fontSize: fontSize
    },
    overrides: {
        RaListToolbar: {
            toolbar: {
                alignItems: 'flex-end'
            }
        },
        RaFilterForm: {
            form: {
                alignItems: 'flex-start',
                minHeight: 'auto',
                paddingTop: 16,
                paddingBottom: 6
            }
        },
        RaSelectInput: {
            input: {
                minWidth: 180
            }
        },
        RaCardContentInner: {
            root: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
            }
        },
        RaFormInput: {
            input: {
                width: 220
            }
        },
        CustomFormInput: {
            input: {
                width: 220
            }
        },
        MuiTableCell: {
            paddingNone: {
                padding: '0px 12px'
            },
            sizeSmall: {
                padding: '0px 12px',
                height: 48
            },
            head: {
                fontSize: '0.65rem',
                lineHeight: 'normal'
            }
        },
        MuiInput: {
            input: {
                width: '100%'
            }
        },
        MuiButtonBase: {
            root: {
                '&:disabled': {
                    cursor: 'default',
                    pointerEvents: 'auto'
                }
            }
        },
        MuiMenuItem: {
            root: {
                height: 36
            }
        },
        MuiAppBar: {
            root: {
                zIndex: 1301
            }
        }
    }
});
