import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Card, LinearProgress} from '@material-ui/core';
import {getTicketsBarChart} from '../../actions/dashboard';
import ReactHighcharts from 'react-highcharts';
import {withTranslate} from 'react-admin';
import compose from 'recompose/compose';

const type = 'tickets';

const chartConfig = {
    title: {
        text: null
    },
    credits: {
        enabled: false
    },
    colors: ['#2196F3', '#4CAF50', '#FFC107', '#FF5722', '#607D8B', '#9C27B0', '#795548', '#673AB7', '#009688', '#E91E63'],
    chart: {
        type: 'column',
        height: 290
    },
    series: [{
        name: '',
        data: []
    }],
    plotOptions: {
        column: {
            stacking: 'stream'
        }
    },
    tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
    },
};


const styles = {
    cardContent: {
        padding: 10,
        paddingTop: 40
    },
    filterContainer: {
        display: 'inline-block'
    },
    select: {
        width: 'auto',
        minWidth: 150,
        marginLeft: 20,
    }
};

class TicketsBarChart extends Component {

    componentDidMount() {
        const {getTicketsBarChart, agency, period} = this.props;
        getTicketsBarChart(agency, period);
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const {getTicketsBarChart, agency, period, isLoading} = this.props;
        const chart = this.refs.chart && this.refs.chart.getChart();
        if (agency !== prevProps.agency || period !== prevProps.period) {
            getTicketsBarChart(agency, period);
        } else if (chart && !isLoading) {
            chart.reflow = () => {
            };
        }
    }

    render() {
        const {isLoading, data, translate} = this.props;
        const series = [];
        if (data && data.series) {
            data.series.forEach(serie => {
                series.push({
                    name: translate(`dashboard.barChart.${serie.name}`),
                    data: serie.data,
                    _colorIndex: serie._colorIndex
                })
            })
        }
        const barChartConfig = {
            xAxis: {categories: data && data.keys ? data.keys : []},
            yAxis: {title: {text: translate(`dashboard.barChart.yAxisTitles.${type}`)}},
            series: series
        };
        return (
            <Card style={{height: '100%'}}>
                <div style={{position: 'relative'}}>
                    {isLoading && <LinearProgress mode="indeterminate" style={{position: 'absolute'}}/>}
                    <div style={styles.cardContent}>
                        {(data && barChartConfig.series.length > 0) &&
                        <ReactHighcharts config={{...chartConfig, ...barChartConfig}} ref="chart"/>}
                    </div>
                </div>
            </Card>
        );
    }
}

TicketsBarChart.propTypes = {
    agency: PropTypes.number,
    period: PropTypes.number
};

const mapStateToProps = state => {
    let stateToProps = {};
    if (state.dashboard && state.dashboard.barChart) {
        stateToProps = {
            isLoading: state.dashboard.barChart.tickets.isLoading,
            data: state.dashboard.barChart.tickets.data,
        }
    }
    return stateToProps;
};

const enhance = compose(
    withTranslate,
    connect(mapStateToProps, {getTicketsBarChart})
);

export default enhance(TicketsBarChart);
